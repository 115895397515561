import { Wrapper, Title, TextWrapper, Close, ModalBody, TokenLogo, Steps } from "./modal.styled";
import { approve } from "../../web3/web3.service";
import bigDecimal from "js-big-decimal";
import If from "../../components/If";
import Spinner from "../../components/Spinner";
import React, { useState } from "react";

export const Modal = (props) => {
  const [processing, setProcessing] = useState(false);
  const { selectedTargetToken, close, sourceNetwork, sourceToken, account } = props;

  const handleApproveAndReload = async () => {
    try {
      setProcessing(true);
      await approve(sourceNetwork.args.id, sourceToken.args.token, account, new bigDecimal("0")).then(() => {
        setProcessing(false);
      });
      window.location.reload();
    } catch (error) {
      setProcessing(false);
      console.error(error);
      window.location.reload();
    }
  };

  return (
    <Wrapper>
      <ModalBody>
        <div className="bridgeContainer__actions">
          <Close onClick={() => close(false)}>
            <img src="/images/close.svg" alt="close" />
          </Close>

          <TokenLogo src={selectedTargetToken.args.img} alt="token logo" />

          <Title>USDT Approval</Title>

          <TextWrapper>USDT requires resetting approval when spending limits are too low.</TextWrapper>

          <TextWrapper>Proceed to your wallet</TextWrapper>
          <div className="bridgeContainer__buttons">
            <button onClick={handleApproveAndReload} className="bridgeContainer__nextBtn">
              <span>Approve</span>
              <If condition={processing}>
                <div className="bridgeContainer__loading">
                  <Spinner size={16} color={"#ffffff"} />
                </div>
              </If>
            </button>
          </div>
        </div>
      </ModalBody>

      <Steps>
        <span className="is-active"></span>
        <span></span>
        <span></span>
      </Steps>
    </Wrapper>
  );
};
