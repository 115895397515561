import React from 'react';
import './style.scss';

interface IProps {
  value?: string;
  label: string,
  placeholder: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  error?: string;
}

export const TextInput = ({ value, label, placeholder, onChange, disabled, error }: IProps) => {
  return (
      <div className="text-input__container">
        <label className="text-input__label">
          {label}
        </label>
        <div className="text-input__input">
          <input
              type="text"
              value={value || ''}
              onChange={(e: any) => onChange(e.target.value)}
              placeholder={placeholder}
              disabled={disabled}
          />
        </div>
        {error && (
            <div className="text-input__error">{error}</div>
        )}
      </div>
  );
};
