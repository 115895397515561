import { IProvider } from "./web3.provider";
import { Contract } from "web3-eth-contract";
import { tokenAbi } from "../abi/token";
import { bridgeAbi } from "../abi/bridge";
import config from "../config/config";
import bigDecimal from "js-big-decimal";
import { convertFromWei, convertToWei } from "../web3.utils";

const TronWeb = require("tronweb");
// const HttpProvider = TronWeb.providers.HttpProvider;

export class TronProvider implements IProvider {
  readonly tronWeb: any;

  constructor(tronWeb: any) {
    this.tronWeb = tronWeb;
  }

  public static create(url: string) {
    // const fullNode = new HttpProvider(url);
    // const solidityNode = new HttpProvider(url);
    // const eventServer = new HttpProvider(url);
    const tronWeb = new TronWeb({
      fullHost: "https://api.trongrid.io",
      headers: { "TRON-PRO-API-KEY": "5a8e06dc-d628-485e-b1e5-0ceb8da57ecf" },
      privateKey: "fdc5875a16cde3bd1ba922552cf1f992964f2eb4a1c44cde4152280f323c1490",
    });
    tronWeb.setAddress(process.env.REACT_APP_TRON_BRIDGE_CONTRACT);
    return new TronProvider(tronWeb);
  }

  public getAccount(): Promise<string> {
    return this.tronWeb.defaultAddress.base58;
  }

  public async getNetworkId(): Promise<number> {
    // contract not exist -> network not support
    try {
      await this.listTokens(config.tron.bridge);
    } catch (e) {
      return Promise.resolve(Number.MAX_VALUE);
    }
    return Promise.resolve(config.tron.networkId);
  }

  public toAmount(amount: any, decimals: number): bigDecimal {
    if (amount._isBigNumber) {
      return convertFromWei(this.tronWeb.BigNumber(amount._hex).toFixed(), decimals);
    }
    return convertFromWei(amount, decimals);
  }

  public async getTokenBalance(account: string, contract: string, decimals: number): Promise<bigDecimal> {
    const tokenContract: Contract = await this.tronWeb.contract(tokenAbi, contract);
    const balance = await tokenContract.methods.balanceOf(account).call();
    return this.toAmount(balance, decimals);
  }

  public async getTokenName(contract: string): Promise<string> {
    const tokenContract: Contract = await this.tronWeb.contract(tokenAbi, contract);
    return tokenContract.methods.name().call();
  }

  public async getTokenSymbol(contract: string): Promise<string> {
    const tokenContract: Contract = await this.tronWeb.contract(tokenAbi, contract);
    return tokenContract.methods.symbol().call();
  }

  public async listDestinations(contract: string): Promise<number[]> {
    const bridgeContract: Contract = await this.tronWeb.contract(bridgeAbi, contract);
    return bridgeContract.methods.getDestinations().call();
  }

  public async listTokens(contract: string): Promise<any[]> {
    const bridgeContract: Contract = await this.tronWeb.contract(bridgeAbi, contract);
    const tokensList = await bridgeContract.methods.getTokens().call();
    const newList = tokensList.map((token: any, index: number) => {
      token[0] = this.tronWeb.address.fromHex(token[0]);
      token[3] = this.tronWeb.address.fromHex(token[3]);
      token[2] = token[2].toString();
      token[5] = token[5].toString();
      token[6] = token[6].toString();
      token[7] = token[7].toString();
      token[1] = token[1].toString();
      token[8] = token[8].toString();
      token[4] = token[8].toString();
      return token;
    });
    return newList;
  }

  public async allowance(contract: string, address: string, bridgeContract: string): Promise<number> {
    const tokenContract: Contract = await this.tronWeb.contract(tokenAbi, contract);
    return tokenContract.methods.allowance(address, bridgeContract).call();
  }

  public async approve(contract: string, address: string, bridgeContract: string): Promise<any> {
    const tokenContract: Contract = await this.tronWeb.contract(tokenAbi, contract);
    return tokenContract.methods
      .approve(bridgeContract, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")
      .send({ from: address });
  }

  public async createOrder(
    networkId: number,
    address: string,
    contract: string,
    tokenId: number,
    amount: bigDecimal,
    decimals: number,
    destination: number,
    receiptAddress: string,
    onSent: (txid: string) => void,
    onError: (error: any) => void
  ): Promise<void> {
    const bridgeContract: Contract = await this.tronWeb.contract(bridgeAbi, contract);
    const wei = convertToWei(amount, decimals);
    let txid;
    try {
      txid = await bridgeContract.methods
        .create(String(tokenId), String(wei), String(destination), receiptAddress)
        .send({ from: address });
    } catch (error) {
      onError(error);
      return Promise.reject(error);
    }
    onSent(txid);
  }

  public onAccountChange(): void {
    window.addEventListener("message", async (e) => {
      if (e.data.message) {
        if (e.data.message.action === "accountsChanged" || e.data.message.action === "disconnect") {
          window.location.reload();
        }
      }
    });
  }

  public onNetworkChange(): void {
    window.addEventListener("message", async (e) => {
      if (e.data.message && e.data.message.action === "setNode") {
        window.location.reload();
      }
    });
  }
}
