import React, {useEffect, useRef, useState} from "react"
import cn from "classnames"
import useDebounce from "../../hooks/useDebounce";

import { useClickOutside } from "hooks/useClickOutside"
import { ICoin } from "../../pages/Swap/coins"
import {IconArrowDown} from "../../assets/icons";
import {Input} from "../Input";

import closeImg from "assets/images/close-black.svg"
import "./CoinsDropdown.scss"
import {CoinsDropdownItem} from "./CoinDropdowItem";

type Props = {
  currentCoin: ICoin
  onSelect: (coin: ICoin) => void
  isLimit?: boolean
  coins?: ICoin[]
}

export const CoinsDropdown = ({ currentCoin, onSelect, isLimit, coins }: Props): JSX.Element => {
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [filteredCoins, setFilteredCoins] = useState(coins || [])
  const [input, setInput] = useState('')
  const debouncedInput = useDebounce(input, 700)

  useClickOutside(wrapperRef, () => {
    setOpen(false)
  })

  const toggleDropdown = () => setOpen(!open)

  useEffect(() => {
    if (!!coins) {
      if (!debouncedInput) {
        setFilteredCoins(coins)
      } else {
        setFilteredCoins(coins.filter(i => i.name.toLocaleLowerCase().match(debouncedInput.toLowerCase()) || i.symbol.toLocaleLowerCase().match(debouncedInput.toLowerCase())))
      }
    }
  }, [debouncedInput, coins])

  useEffect(() => {
    if (!!coins) {
      setFilteredCoins(coins)
    }
  }, [coins])

  return (
    <div
      className={cn("coin-dropdown", {
        active: open,
        // mobileRight: mobileRight
      })}
    >
      <button onClick={toggleDropdown} className="coin-dropdown-trigger">
        <img src={currentCoin?.logoURI} alt="network" className="coin-dropdown-trigger-img" />
        <div className="coin-dropdown-trigger-name">{currentCoin?.symbol}</div>
        <IconArrowDown />
      </button>
      <div className="coin-dropdown-content">
        <div className="coin-dropdown-content-header">Select Token</div>
        <Input onChange={v => setInput(v)} value={input} placeholder='Search' append={(<button onClick={() => setInput('')} className='coin-dropdown-content-icon'>
          <img src={closeImg} alt='close' />
        </button>)} />
        <div className='coin-dropdown-content-scroll'>
          {filteredCoins?.map((item, index) => (
                  <CoinsDropdownItem currentCoin={currentCoin} onSelect={coin => onSelect(coin)} toggleDropdown={toggleDropdown} item={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  )
}
