import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  onBack: () => void;
}

const Error = ({ onBack }: IProps) => {
  const { t } = useTranslation();
  return (
      <div className="successContainer">
        <div className="successContainer__imageBlock">
          <div className="successContainer__image">
            <img src="/images/error.svg" alt="error"/>
          </div>
        </div>
        <h2>{t('error.transactionFailed')}</h2>
        <p>{t('error.transactionSubheader')}</p>
        <button className="bridgeContainer__nextBtn" onClick={() => onBack()}>
          <span>{t('error.backBtn')}</span>
        </button>
      </div>
  );
};

export default Error;
