import React, { useEffect, useState } from "react";
import { Dropdown, Option } from "../../Dropdown";
import { NumberInput } from "../../NumberInput";
import { loadTokens } from "../../../web3/web3.service";
import { IToken } from "../../../web3/web3.interfaces";
import { networkToTokens } from "../../../web3/web3.networksIds";
import { getCoinImage } from "../../../helpers/coins";
import bigDecimal from "js-big-decimal";
import { useTranslation } from "react-i18next";

const toOption = (token: IToken): Option => {
  return { value: token.contract, label: token.symbol, args: { token, img: getCoinImage(token.symbol) } };
};

interface Props {
  sourceNetwork?: Option;
  targetNetwork?: Option;
  selectedTargetToken?: Option;
  setSourceTokens: (token?: Option) => void;
  onSelectTargetToken: (token?: Option) => void;
  tokenAmount?: bigDecimal;
  tokenBalance: bigDecimal;
  onTokenAmountChange: (amount?: bigDecimal) => void;
  sourceToken?: Option;
  onBack: () => void;
  onNext: () => void;
}

const SelectToken = (props: Props) => {
  const { t } = useTranslation();
  const {
    sourceNetwork,
    targetNetwork,
    selectedTargetToken,
    setSourceTokens,
    onSelectTargetToken,
    tokenAmount,
    tokenBalance,
    onTokenAmountChange,
    sourceToken,
    onBack,
    onNext,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [sourceTargetTokens, setSourceTargetTokens] = useState<Option[]>([]);

  useEffect(() => {
    if (sourceNetwork) {
      setLoading(true);
      const sourceNeworkId = sourceNetwork.args.id;
      const targetNetworkId = targetNetwork?.args.id;
      const networkFee = networkToTokens[sourceNeworkId][targetNetworkId];
      loadTokens(sourceNetwork)
        .then((tokens) => {
          setSourceTokens(tokens.map(toOption)[networkFee]);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Bridge:", error);
          setLoading(false);
        });
    } else {
      setSourceTokens(undefined);
    }
  }, [sourceNetwork, setSourceTokens, targetNetwork?.args.id]);
  useEffect(() => {
    if (targetNetwork) {
      setLoading(true);
      loadTokens(targetNetwork)
        .then((tokens) => {
          const uniqueAddresses = new Set();
          const filteredTokens = tokens.filter((token) => {
            const address = token.contract;
            if (!uniqueAddresses.has(address)) {
              uniqueAddresses.add(address);
              return true;
            }
            return false;
          });
          setSourceTargetTokens(filteredTokens.map(toOption));
          setLoading(false);
        })
        .catch((error) => {
          console.error("Bridge:", error);
          setLoading(false);
        });
    } else {
      setSourceTargetTokens([]);
    }
  }, [targetNetwork, setSourceTargetTokens, sourceNetwork]);

  useEffect(() => {
    if (sourceTargetTokens && sourceTargetTokens.length > 0) {
      onSelectTargetToken(sourceTargetTokens[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceTargetTokens]);

  let error = undefined;
  if (sourceToken) {
    const {
      args: {
        token: { minAmount, maxAmount, feeBase, fee },
      },
    } = sourceToken;

    const percent = fee.divide(new bigDecimal("100"));
    const minAmountWithFee = minAmount.add(feeBase).divide(new bigDecimal("1").subtract(percent));
    if (
      tokenAmount !== undefined &&
      (tokenAmount.compareTo(minAmountWithFee) < 0 || tokenAmount.compareTo(maxAmount) > 0)
    ) {
      error = t("bridge.tokenAmountError", { min: minAmountWithFee.getValue(), max: maxAmount.getValue() });
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        selectedOption={selectedTargetToken}
        label={t("bridge.token")}
        placeholder={t("bridge.selectToken")}
        options={sourceTargetTokens}
        onSelect={(token: Option) => onSelectTargetToken(token)}
        loading={loading}
      />
      <NumberInput
        value={tokenAmount}
        maxValue={tokenBalance}
        label={t("bridge.tokenAmount")}
        placeholder={t("bridge.enterAmount")}
        onChange={(value) => onTokenAmountChange(value)}
        error={error}
        caption={`${t("bridge.available")}: ${tokenBalance.getValue()}`}
      />
      <div className="bridgeContainer__buttons">
        <button onClick={() => onBack()} className="bridgeContainer__backBtn">
          <span>{t("bridge.backBtn")}</span>
        </button>
        <button
          onClick={() => onNext()}
          className="bridgeContainer__nextBtn"
          disabled={
            !selectedTargetToken || !sourceToken || !tokenAmount || !!error || tokenBalance.compareTo(tokenAmount) < 0
          }
        >
          <span>
            {tokenAmount && tokenBalance.compareTo(tokenAmount) < 0
              ? t("bridge.insufficientFunds")
              : t("bridge.nextBtn")}
          </span>
        </button>
      </div>
    </React.Fragment>
  );
};

export default SelectToken;
