import React, { useRef, useState } from 'react';
import './style.scss';
import Popup from '../Popup';
import If from '../If';
import Spinner from '../Spinner';

export interface Option {
  value: string;
  label: string;
  args?: any;
}

interface IProps {
  selectedOption?: Option;
  label: string,
  placeholder: string;
  options: Option[],
  onSelect: (option: Option) => void;
  disabled?: boolean;
  loading?: boolean;
}

export const Dropdown = ({ selectedOption, label, placeholder, options, onSelect, disabled, loading }: IProps) => {
  const ref = useRef<any>(null);

  const [open, setOpen] = useState(false);

  const handleOnOpen = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  const handleOnSelect = (option: Option) => {
    if (!disabled) {
      onSelect(option);
    }
  };

  return (
      <div ref={ref} className="dropdown" onClick={handleOnOpen}>
        <label className={`dropdown__label ${disabled ? 'dropdown__label_disabled' : ''}`} htmlFor="dropdown__defaultValue">
          {label}
        </label>
        <div className="dropdown__value" aria-disabled={disabled}>
          <div className="dropdown__option">
            <If condition={!!loading}>
              <Spinner size={16}/>
            </If>
            <If condition={!loading}>
              {selectedOption && selectedOption.args?.img && (
                  <img src={selectedOption.args?.img} alt={selectedOption.value}/>
              )}
              {selectedOption && (
                  <span>{selectedOption.label}</span>
              )}
              {!selectedOption && (
                  <span className="dropdown__placeholder">{placeholder}</span>
              )}
            </If>
          </div>
          <span className="dropdown__arrow">
              <img className={`dropdown__arrowImage ${open ? 'dropdown__arrowImage_rotated' : ''}`}
                   src="/images/arrow-down.svg" alt="dropdown__arrow"/>
            </span>
        </div>
        <div className={`dropdown__list ${open ? 'show' : ''}`}>
          <Popup containerRef={ref} open={open} onOpen={(open) => !disabled && setOpen(open)}>
            {options.map((option: Option, idx) => (
                <div key={idx} className="dropdown__listItem" onClick={() => handleOnSelect(option)}>
                  {option.args?.img && (
                      <img src={option.args?.img} alt={option.value}/>
                  )}
                  {option.label}
                </div>
            ))}
          </Popup>
        </div>
      </div>
  );
};
