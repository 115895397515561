import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

interface Props {
    size?: number;
    color?: string;
}

const Spinner = ({ size, color }: Props) => {
    return <ClipLoader color={color || '#850CE3'} loading={true} size={size || 24}/>;
};

export default Spinner;