import { TronProvider } from '../providers/tron.provider';
import { IProvider } from '../providers/web3.provider';

export const connectTronLink = async (): Promise<IProvider> => {
  // @ts-ignore
  const tronLink = window.tronLink;
  if (tronLink) {
    let response;
    try {
      response = await tronLink.request({ method: 'tron_requestAccounts' });
    } catch (error) {
      return Promise.reject(error);
    }
    if (!response) {
      return Promise.reject('tronlink_lock');
    }
    if (response.code === 200) {
      return new TronProvider(tronLink.tronWeb);
    } else {
      return Promise.reject(response.message);
    }
  } else {
    return Promise.reject('tronlink_uninstall');
  }
};