import { useEffect, useState } from 'react';
import AccountContext from './AccountContext';
import { IProvider } from '../web3/providers/web3.provider';
import {
  Connectors,
  connectTo,
  connectToNetwork,
  disconnect,
  getCacheConnector,
  isConnected,
} from '../web3/web3.connector';
import { displayAlert } from '../helpers/alert';
import { getBridge } from '../web3/config/config-utils';

const AccountProvider = ({ children }: any) => {
  const [loading, setLoading] = useState<boolean>(isConnected());
  const [account, setAccount] = useState<string | undefined>();
  const [networkId, setNetworkId] = useState<number | undefined>();

  useEffect(() => {
    const cacheProvider = getCacheConnector();
    if (cacheProvider) {
      connectAccount(cacheProvider).catch(console.log);
    }
    // eslint-disable-next-line
  }, []);

  const disconnectAccount = async () => {
    setAccount(undefined);
    setNetworkId(undefined);
    setLoading(false);
    disconnect();
  };

  const connectAccount = async (id: Connectors | string): Promise<any> => {
    return connectAccountTo(id);
  };

  const connectAccountTo = async (id: Connectors | string, network?: any): Promise<any> => {
    setLoading(true);
    return (network ? connectToNetwork(id, network) : connectTo(id))
        .then(async (provider: IProvider) => {
          const [account, networkId] = await Promise.all([
            provider.getAccount(),
            provider.getNetworkId(),
          ]);

          const bridge = getBridge(networkId);
          if (!bridge || (await provider.listDestinations(bridge)).length === 0) {
            displayAlert('Network not supported');
            await disconnectAccount();
            return;
          }

          provider.onAccountChange();
          provider.onNetworkChange();

          setLoading(false);
          setAccount(account);
          setNetworkId(networkId);
          return Promise.resolve(provider);
        })
        .catch(error => {
          disconnectAccount();
          return Promise.reject(error);
        });
  };

  return (
      <AccountContext.Provider
          value={{
            loading,
            account,
            networkId,
            connect: connectAccount,
            connectTo: connectAccountTo,
            disconnect: disconnectAccount,
          }}
      >
        {children}
      </AccountContext.Provider>
  );
};

export default AccountProvider;
