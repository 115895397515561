import {Wrapper} from "./info.styled";
import {Title, Button} from "componentsNew";
import {StyledFlex} from "common/styled-components/helpers";
import {IconSwap} from "assets/icons";
import Fade from 'react-reveal/Fade';

export const Info = (props) => {
  const {options, className} = props;

  return (
      <Wrapper className={className}>
          <StyledFlex direction="column" align="flex-start" justify="center">
            <Fade bottom distance={'20px'}>
              <Title options={options}/>
            </Fade>
            <Fade bottom distance={'20px'} delay={100}><p>{options.descr}</p></Fade>

            <Fade bottom distance={'20px'} delay={200}>
              <Button className="button" link={options?.link} to={options?.to} target={options?.target}>
                {options?.icon && <IconSwap width="2.4rem" height="2.4rem" />}
                {options?.btn}
              </Button>
            </Fade>

          </StyledFlex>
      </Wrapper>
  )
}