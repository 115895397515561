import { IProvider } from '../providers/web3.provider';
import Web3Utils from 'web3-utils';
import { getBridge, isTronNetwork } from '../config/config-utils';
import { EvmProvider } from '../providers/evm.provider';
import config from '../config/config';

export const connectMetaMask = async (): Promise<IProvider> => {
  if (typeof window.ethereum !== 'undefined') {
    const ethereum = window.ethereum;
    const chainId = await ethereum.request({ method: 'eth_chainId' });
    const networkId = Web3Utils.toDecimal(chainId);
    const bridge = getBridge(networkId);
    if (bridge) {
      const provider: IProvider = new EvmProvider(ethereum);
      const destinations: any[] = await provider.listDestinations(bridge);
      if (destinations.length === 0) {
        return switchNetwork(ethereum);
      }
    } else {
      return switchNetwork(ethereum);
    }
    return requestAccount(ethereum);
  } else {
    return Promise.reject('metamask_uninstall');
  }
};

export const connectMetaMaskTo = async (network: any): Promise<IProvider> => {
  if (typeof window.ethereum !== 'undefined') {
    return switchNetwork(window.ethereum, network);
  } else {
    return Promise.reject('metamask_uninstall');
  }
}

const requestAccount = async (provider: any): Promise<IProvider> => {
  try {
    await provider.request({ method: 'eth_requestAccounts' });
  } catch (error) {
    return Promise.reject('User rejected request accounts');
  }
  return new EvmProvider(provider);
};

export const switchNetwork = async (provider: any, networkTo?: any): Promise<IProvider> => {
  const network = networkTo ? networkTo : await getAvailableNetwork();
  if (network) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3Utils.toHex(network.networkId) }],
      });
    } catch (error) {
      if (error.code === 4902) {
        return addNetwork(provider, network);
      } else if (error.code === 4001) {
        return Promise.reject('User rejected switch network');
      } else {
        return Promise.reject({ code: 'unknown', message: error });
      }
    }
  } else {
    return Promise.reject('no_networks');
  }
  return requestAccount(provider);
};

const addNetwork = async (provider: any, network: any) => {
  try {
    await provider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: Web3Utils.toHex(network.networkId),
          chainName: network.name,
          rpcUrls: [network.rpcUrl],
          nativeCurrency: {
            symbol: network.symbol,
            decimals: 18,
          },
        },
      ],
    });
  } catch (error) {
    if (error.code === 4001) {
      return Promise.reject('User rejected add ethereum network');
    } else {
      return Promise.reject({ code: 'unknown', message: error });
    }
  }
  return switchNetwork(provider, network);
};

const getAvailableNetwork = async (): Promise<any | null> => {
  for (const network of Object.values(config)) {
    if (!isTronNetwork(network.networkId)) {
      const provider: IProvider = EvmProvider.create(network.rpcUrl);
      const destinations: any[] = await provider.listDestinations(network.bridge);
      if (destinations.length > 0) {
        return network;
      }
    }
  }
  return null;
};