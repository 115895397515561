import format from 'date-fns/format';

export const toAmountFromString = (amount: string) => {
  return Number(amount.replace(/([0-9]+(\.[1-9]+)?)(\.?0+$)/, '$1'));
};

export const toAddress = (address: string, isMobile: boolean) => {
  if (!address || !isMobile) {
    return address;
  }
  return `${address.slice(0, 6)}...${address.slice(-6)}`;
};

export const toDate = (date: string) => {
  if (!date) {
    return date;
  }
  return format(new Date(date), 'HH:mm:ss dd/MM/yyyy');
};