import React, { useContext, useMemo } from 'react';
import './Wallet.scss';
import AccountContext from '../../provider/AccountContext';
import { getInjected } from '../../web3/connectors/injected.connector';
import { Connectors } from '../../web3/web3.connector';
import { getWalletImage } from '../../helpers/wallets';
import { useTranslation } from 'react-i18next';
import { handleBinanceSmartChainError, handleMetaMaskErrors, handleTronLinkError } from '../../helpers/errors';

interface Props {
  onClose: () => void;
}

const Wallet = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const { connect } = useContext(AccountContext);

  const injected = useMemo(() => getInjected(), []);

  const handleConnectMetaMask = () => {
    connect(Connectors.metamask).catch(handleMetaMaskErrors);
  }

  const handleConnectMetaMaskMobile = () => {
    if (typeof window.ethereum !== 'undefined') {
      handleConnectMetaMask();
    } else {
      window.open(`https://metamask.app.link/dapp/${process.env.REACT_APP_DAPP_URL}`, '_blank');
    }
  };

  const handleConnectInjected = (name: string) => {
    if (name === 'MetaMask') {
      handleConnectMetaMask();
    } else {
      connect(Connectors.injected).catch(console.error);
    }
  };

  const handleConnectWalletConnect = () => {
    connect(Connectors.walletconnect).catch(console.error);
  };

  const handleBinanceSmartChainConnect = () => {
    connect(Connectors.binancesmartchain).catch(handleBinanceSmartChainError);
  };

  const handleTronLinkConnect = () => {
    connect(Connectors.tronlink).catch(handleTronLinkError);
  };

  return (
      <div className="walletContainer">
        <div className="header__block">
          <h1>{t('common.connectWallet')}</h1>
          <button onClick={() => onClose()} className="button button_close">
            <img src="/images/close.svg" alt="close"/>
          </button>
        </div>
        <div className="walletContainer__wallets">
          {injected ? (
              <div className="walletContainer__wallet" onClick={() => handleConnectInjected(injected.name)}>
                <img src={getWalletImage(injected)} width={32} height={32} alt="Wallet"/>
                <p>{injected.name}</p>
              </div>
          ) : (
              <div className="walletContainer__wallet" onClick={handleConnectMetaMaskMobile}>
                <img src="/images/metamask.svg" alt="MetaMask"/>
                <p>MetaMask</p>
              </div>
          )}
          <div className="walletContainer__wallet" onClick={handleConnectWalletConnect}>
            <img src="/images/walletconnect.svg" alt="WalletConnect"/>
            <p>WalletConnect</p>
          </div>
          <div className="walletContainer__wallet" onClick={handleBinanceSmartChainConnect}>
            <img src="/images/binancewallet.svg" alt="Binance Chain Wallet"/>
            <p>Binance Chain Wallet</p>
          </div>
          <div className="walletContainer__wallet" onClick={handleTronLinkConnect}>
            <img src="/images/tronlink.png" alt="TronLink Wallet" width={32} height={32}/>
            <p>TronLink</p>
          </div>
        </div>
      </div>
  );
};

export default Wallet;
