import Web3Modal from 'web3modal';
import { IProviderUserOptions } from 'web3modal/dist/helpers';
import { EvmProvider } from '../providers/evm.provider';
import { IProvider } from '../providers/web3.provider';

const web3Modal: Web3Modal = new Web3Modal({ theme: 'dark' });

export const connectInjected = async (): Promise<IProvider> => {
  let provider;
  try {
    provider = await web3Modal.connectTo('injected');
  } catch (error) {
    return Promise.reject(error);
  }
  return new EvmProvider(provider);
};

export const getInjected = (): IProviderUserOptions | undefined => {
  return web3Modal.getUserOptions()[0];
};