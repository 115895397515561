import React, { useContext, useEffect, useState } from "react";
import Bridge from "../../components/Bridge";
import Wallet from "../../components/Wallet";
import History from "../../components/History";
import AccountContext from "../../provider/AccountContext";
import { Layout } from "../../components/Layout/Layout";
import If from "../../components/If";
import Spinner from "../../components/Spinner";
import "./BridgePage.scss";
import HistoryDetails from "../../components/HistoryDetails";
import Progress from "../../components/Progress";
import { IOrder } from "../../api/order";
import { isConnected } from "../../web3/web3.connector";
import Error from "../../components/Error";

import { StyledFlex } from "common/styled-components/helpers";

enum View {
  wallet,
  bridge,
  history,
  historyDetails,
  progress,
  error,
}

const BridgeDevPage = () => {
  const { account, loading } = useContext(AccountContext);

  const [view, setView] = useState<View>(isConnected() ? View.bridge : View.wallet);
  const [history, setHistory] = useState<IOrder>();
  const [progressStartDate, setProgressStartDate] = useState<Date>();

  useEffect(() => {
    if (account && view === View.wallet) {
      setView(View.bridge);
    } else if (!account) {
      setView(View.wallet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <Layout className="bridge-page" onWalletConnectClick={() => setView(View.wallet)}>
      <StyledFlex align="center" justify="center" style={{ flexGrow: "1" }}>
        <If condition={loading}>
          <div className="bridge_page__loading">
            <Spinner />
          </div>
        </If>
        <If condition={!loading}>
          <If condition={view === View.wallet}>
            <Wallet onClose={() => setView(View.bridge)} />
          </If>
          <If condition={view === View.history}>
            <History
              onClose={() => setView(View.bridge)}
              onHistorySelect={(history) => {
                setHistory(history);
                setView(View.historyDetails);
              }}
            />
          </If>
          <If condition={view === View.historyDetails}>
            {history && (
              <HistoryDetails
                order={history}
                onUpdate={(order) => setHistory(order)}
                onClose={() => setView(View.history)}
              />
            )}
          </If>
          <If condition={view === View.bridge}>
            <Bridge
              onHistory={() => setView(View.history)}
              onProgress={(startDate) => {
                setProgressStartDate(startDate);
                setView(View.progress);
              }}
              onError={() => setView(View.error)}
            />
          </If>
          <If condition={view === View.progress}>
            {progressStartDate && (
              <Progress
                startDate={progressStartDate}
                onViewProgress={(transaction) => {
                  if (transaction) {
                    setHistory(transaction);
                    setView(View.historyDetails);
                  } else {
                    setView(View.history);
                  }
                }}
              />
            )}
          </If>
          <If condition={view === View.error}>
            <Error onBack={() => setView(View.bridge)} />
          </If>
        </If>
      </StyledFlex>
    </Layout>
  );
};

export default BridgeDevPage;
