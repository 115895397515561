import { useContext, useEffect } from 'react';
import { fetchOrders, IOrder } from '../../api/order';
import AccountContext from '../../provider/AccountContext';

let interval: any;
let intervalExpiredAt: number | undefined;

interface Props {
  children: any;
  startDate: Date;
  waitInterval: number;
  waitMaxTime: number;
  onLoading: (loading: boolean) => void;
  onFound: (order: IOrder) => void;
}

const WaitOrder = ({ children, startDate, waitInterval, waitMaxTime, onLoading, onFound }: Props) => {
  const { account, networkId } = useContext(AccountContext);

  const doClearInterval = () => {
    clearInterval(interval);
    interval = undefined;
    intervalExpiredAt = undefined;
  };

  const listenOrder = async (startDate: Date, account: string, networkId: number): Promise<void> => {
    if (intervalExpiredAt && intervalExpiredAt < new Date().getTime()) {
      doClearInterval();
      onLoading(false);
      return;
    }
    try {
      const response = await fetchOrders(account, networkId, 1, 1);
      if (response.data.data.length > 0) {
        const order = response.data.data[0];
        if (new Date(order.acceptedAt).getTime() > startDate.getTime()) {
          doClearInterval();
          onLoading(false);
          onFound(order);
          return;
        }
      }
    } catch (e) {
    }
  };

  useEffect(() => {
    if (account && networkId && !interval) {
      intervalExpiredAt = new Date().getTime() + waitMaxTime * 1000;
      interval = setInterval(() => listenOrder(startDate, account, networkId), waitInterval * 1000);
    }
    return () => doClearInterval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, networkId, startDate]);

  return children;
};

export default WaitOrder;