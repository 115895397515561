import axios from "axios";
import { getDestination } from "../web3/config/config-utils";

const apiUrl = process.env.REACT_APP_API_URL;

export interface IOrder {
  id: string;
  txid: string;
  status: "NEW" | "CREATED" | "COMPLETED" | "CLOSED" | "FAILED";
  amount: string;
  totalAmount: string;
  token: string;
  fromClient: {
    address: string;
    network: string;
  };
  toClient: {
    address: string;
    network: string;
  };
  acceptedAt: string;
  confirmedAt: string;
  withdrawnAt: string;
  completedAt: string;
}

export interface IPage {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface OrderResponse {
  data: IOrder[];
  meta: IPage;
}

export const fetchOrders = (
  address: string,
  networkId: number,
  page: number,
  take: number
): Promise<{ data: OrderResponse }> => {
  return axios.post(
    `${apiUrl}/api/orders`,
    {
      client: {
        address,
        network: getDestination(networkId),
      },
    },
    {
      params: {
        page,
        take,
      },
    }
  );
};
