import styled from "styled-components";

export const Wrapper = styled.div`
  p {
    color: ${({theme}) => theme.colors.text.gray};
    margin-top: 4rem;
  }
  
  .button {
    width: 100%;
    margin-top: 8rem;
    
    svg {
      display: inline-block;
      margin-right: 1rem;
    }
  }

  .react-reveal {
    width: 100%;
  }
`