import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({theme}) => theme.colors.mainBgLighten};
  padding: 2rem;
  z-index: 30;
  text-align: center;
  max-width: 40rem;
  
  border-radius: 1.6rem;
  border: 1px solid ${({theme}) => theme.colors.border};
`
export const ModalBody = styled.div``
export const Title = styled.h3`
  font-size: 2.4rem;
  margin-bottom: 1.6rem;
`
export const TextWrapper = styled.p`
  color: ${({theme}) => theme.colors.text.gray};
  
  &:not(:last-child) {
    margin-bottom: 6rem;
  }
`
export const Close = styled.button`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 1rem;
  
  background-color: ${({theme}) => theme.colors.border};
  cursor: pointer;
`

export const TokenLogo = styled.img`
  width: 10rem;
  height: 10rem;
  margin-bottom: 2rem;
`

export const Steps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  margin-top: 2rem;
  
  span {
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 100%;

    background-color: ${({theme}) => theme.colors.text.gray};
    
    &.is-active {
      background-color: ${({theme}) => theme.colors.primary};
    }
    
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
`