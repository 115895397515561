import { IProviderUserOptions } from 'web3modal/dist/helpers';
import { Connectors, getCacheConnector } from '../web3/web3.connector';
import { getInjected } from '../web3/connectors/injected.connector';

export const getWalletImage = (provider: IProviderUserOptions) => {
  switch (provider.name) {
    case 'MetaMask':
      return '/images/metamask.svg';
    case 'Math Wallet':
      return '/images/mathwallet.svg';
    default:
      return provider.logo;
  }
};

export const getCurrentWalletImage = () => {
  const cacheConnector = getCacheConnector();
  if (cacheConnector) {
    switch (cacheConnector) {
      case Connectors.injected:
        const injected = getInjected();
        return injected ? getWalletImage(injected) : undefined;
      case Connectors.metamask:
        return '/images/metamask.svg';
      case Connectors.walletconnect:
        return '/images/walletconnect.svg';
      case Connectors.binancesmartchain:
        return '/images/binancewallet.svg';
      case Connectors.tronlink:
        return '/images/tronlink.png';
    }
  } else {
    return undefined;
  }
};