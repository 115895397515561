export const contractAddressUsdcApprove = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
export const contractAddressLinkApprove = "0x514910771af9ca656af840dff83e8264ecf986ca"
export const contractAddressWbtcApprove = "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599"
export const contractAddressUniApprove = "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984"
export const contractAddressApeApprove = "0x4d224452801aced8b2f0aebe155379bb5d594381"
export const contractAddressWethApprove = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
export const contractAddressBusdApprove = "0xe9e7cea3dedca5984780bafc599bd69add087d56"
export const contractAddressCakeApprove = "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82"
export const contractAddressBabyDogeApprove = "0xc748673057861a797275cd8a068abb95a902e8de"
export const contractAddressTwtApprove = "0x4b0f1812e5df2a09796481ff14017e6005508003"
export const contractAddressGmtApprove = "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1"
export const contractAddressC98Approve = "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6"
export const approveAddress = "0xdac17f958d2ee523a2206206994597c13d831ec7"