import { connectInjected } from './connectors/injected.connector';
import { connectWalletConnect } from './connectors/walletconnect.connector';
import { connectBinanceSmartChain } from './connectors/binancesmartchain.connector';
import { connectTronLink } from './connectors/tronlink.connector';
import { IProvider } from './providers/web3.provider';
import { connectMetaMask, connectMetaMaskTo } from './connectors/metamask.connector';

export enum Connectors {
  injected = 'injected',
  metamask = 'metamask',
  walletconnect = 'walletconnect',
  binancesmartchain = 'binancesmartchain',
  tronlink = 'tronlink'
}

const connectorsMap: { [key: string]: any } = {
  [Connectors.injected]: connectInjected,
  [Connectors.metamask]: connectMetaMask,
  [Connectors.walletconnect]: connectWalletConnect,
  [Connectors.binancesmartchain]: connectBinanceSmartChain,
  [Connectors.tronlink]: connectTronLink,
};

export const connectTo = async (id: Connectors | string): Promise<IProvider> => {
  const connect = connectorsMap[id];
  try {
    const provider = await connect();
    localStorage.setItem('connector', id);
    return provider;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const connectToNetwork = async (id: Connectors | string, network: any): Promise<IProvider> => {
  try {
    const provider = await connectMetaMaskTo(network);
    localStorage.setItem('connector', id);
    return provider;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const connectCached = async (): Promise<IProvider> => {
  const connector = localStorage.getItem('connector');
  if (!connector || !connectorsMap[connector]) {
    return Promise.reject('connector not found');
  }
  return connectTo(connector);
};

export const getCacheConnector = () => {
  return localStorage.getItem('connector');
};

export const isConnected = () => {
  return !!localStorage.getItem('connector');
};

export const disconnect = () => {
  return localStorage.removeItem('connector');
};
