import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import {GlobalStyle, GlobalThemes} from "common/styled-components/helpers";
import { Web3ReactProvider } from "@web3-react/core"
import { Web3Provider } from "@ethersproject/providers"

import 'I18n/config';

function getLibrary(provider: any) {
  return new Web3Provider(provider)
}

ReactDOM.render(
        <Web3ReactProvider getLibrary={getLibrary}>
          <GlobalThemes>
            <GlobalStyle/>
            <App />
          </GlobalThemes>
        </ Web3ReactProvider>
    ,
    document.getElementById('root')
);