import { useEffect, useRef } from "react"
import cn from "classnames"
import { useMedia } from "use-media"
import { useWeb3React } from "@web3-react/core"

import "./ConnectModal.scss"
import walletConnectIcon from "assets/images/wallet-connect.svg"
import metamaskIcon from "assets/images/metamask.svg"
import imtoken from "assets/images/imtoken.jpeg"
import {mixins} from "helpers/mixins";
import {useClickOutside} from "hooks/useClickOutside";
import {connectors} from "helpers/connectors";
import {setCookie} from "../../helpers/cookies";

type Props = {
  onClose: () => void
}

export const ConnectModal = ({ onClose }: Props): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const isM = useMedia({ maxWidth: mixins.m })

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [])

  const handleClose = () => {
    onClose()
  }

  useClickOutside(wrapperRef, () => {
    handleClose()
  })

  const { activate } = useWeb3React()

  const setProvider = (type: string) => {
    window.localStorage.setItem("provider", type)
  }

  const connectWays = [
    {
      id: "metamask",
      icon: <img src={metamaskIcon} alt='metamask' className="connect-content-item-icon" />,
      onClick: () => onConnect("metamask"),
      title: "Metamask",
    },
    {
      id: "walletConnect",
      icon: <img src={walletConnectIcon} alt='walletConnect' className="connect-content-item-icon" />,
      onClick: () => onConnect("walletConnect"),
      title: "Wallet Connect",
    },
    {
      id: "metamask",
      icon: <img src={imtoken} alt="imtoken" className="connect-content-item-img" />,
      onClick: () => onConnect("metamask"),
      title: "imToken",
      hideMobile: true,
    },
    {
      id: "metamask",
      icon: <img src={imtoken} alt="imtoken" className="connect-content-item-img" />,
      link: "imtokenv2://navigate/DappView?url=https://xrow.cc/?imtoken=true",
      title: "imToken",
      showMobile: true,
    },
  ]

  const onConnect = (way: string) => {
    // @ts-ignore
    const { ethereum } = window

    if (isM && window.location.href.includes("https://xrow.cc/") && !ethereum) {
      if (way === "metamask") {
        window.location.href = "https://metamask.app.link/dapp/https://xrow.cc/?metamask=true"
        activate(connectors.injected)
        setCookie('logged', 'true', 365)
        return
      }
      if (way === "walletConnect") {
        activate(connectors.walletConnect).then(() => {
          handleClose()
        })
        setProvider("walletConnect")
        return
      }
      window.location.href =
        "https://link.trustwallet.com/open_url?coin_id=20000714&url=https://xrow.cc/?trust=true"
      activate(connectors.injected)
    }
    if (isM && !!ethereum) {
      return activate(connectors.injected).then(() => {
        handleClose()
        setCookie('logged', 'true', 365)
      })
    }
    if (way === "metamask") {
      activate(connectors.injected).then(() => {
        handleClose()
      })
      setProvider("injected")
    }
    if (way === "walletConnect") {
      activate(connectors.walletConnect).then(() => {
        handleClose()
      })
      setProvider("walletConnect")
    }
  }

  return (
    <div className="mint-modal-wrapper">
      <div ref={wrapperRef} className="mint-modal-content animate__animated animate__zoomIn animate__faster">
        <div className="mint-modal-content-title">Connect Wallet</div>
        <p className="mint-modal-content-desc">
          Start by connecting with one of the wallets below. Be sure to store your <br /> private keys or sed phase
          securely. Never share them with anyone.
        </p>
        <div className="mint-modal-content-ways">
          {connectWays.map((item, index) =>
            item.onClick ? (
              <button
                type="button"
                key={index}
                onClick={item.onClick}
                className={cn("mint-modal-content-item", {
                  hide: isM && item.hideMobile && !window.location.href.includes("imtoken=true"),
                  showMobile: item.showMobile,
                })}
              >
                {item.icon}
                <div className="mint-modal-content-item-title">{item.title}</div>
              </button>
            ) : (
              <a
                key={index}
                href={item.link}
                className={cn("mint-modal-content-item", {
                  hide: window.location.href.includes("imtoken=true"),
                  showMobile: item.showMobile,
                })}
              >
                {item.icon}
                <div className="mint-modal-content-item-title">{item.title}</div>
              </a>
            ),
          )}
        </div>
      </div>
    </div>
  )
}
