import Web3Utils from 'web3-utils';
import bigDecimal from 'js-big-decimal';

export const convertFromWei = (amount: any, decimals: number): bigDecimal => {
  const unit: any = getUnit(decimals);
  return new bigDecimal(Web3Utils.fromWei(String(amount), unit));
}

export const convertToWei = (amount: bigDecimal, decimals: number): any => {
  const unit: any = getUnit(decimals);
  return Web3Utils.toWei(amount.getValue(), unit);
}

export const getUnit = (decimals: number): any => {
  return Object
      .keys(Web3Utils.unitMap)
      // @ts-ignore
      .find(key => Web3Utils.unitMap[key] === Web3Utils.toBN(10).pow(Web3Utils.toBN(decimals)).toString());
}