import React, { useState, useEffect } from "react";
import bigDecimal from "js-big-decimal";
import "./style.scss";
import { useTranslation } from "react-i18next";

interface IProps {
  value?: bigDecimal;
  maxValue?: bigDecimal;
  label: string;
  placeholder: string;
  onChange: (value?: bigDecimal) => void;
  disabled?: boolean;
  error?: string;
  caption?: string;
}

export const NumberInput = ({ value, maxValue, label, placeholder, onChange, disabled, error, caption }: IProps) => {
  const { t } = useTranslation();

  const [isValue, setIsValue] = useState<number | string>(value?.getValue() || "");

  const handlerIntegerNumber = (val: bigDecimal) => {
    const floatValue = parseFloat(val?.getValue());
    if (!isNaN(floatValue)) {
      return floatValue;
    } else {
      return val?.getValue() || "";
    }
  };

  const handlerValue = (val: bigDecimal): string | number => {
    return val?.getValue();
  };

  const handlerOnBlur = (e: any): void => {
    setIsValue(handlerIntegerNumber(new bigDecimal(e.target.value)));
  };

  const handlerOnChange = (e: any): void => {
    onChange(e.target.value ? new bigDecimal(e.target.value) : undefined);

    setIsValue(handlerValue(new bigDecimal(e.target.value) as bigDecimal));
  };

  useEffect(() => {
    if (value) {
      setIsValue(handlerIntegerNumber(value));
    }
  }, [value]);

  return (
    <div className="number-input__container">
      <label className="number-input__label">{label}</label>
      <div className="number-input__input">
        <input
          type="number"
          value={isValue}
          onBlur={(e: any) => handlerOnBlur(e)}
          onChange={(e: any) => handlerOnChange(e)}
          placeholder={placeholder}
          disabled={disabled}
        />
        {maxValue ? (
          <div className="number-input__max" onClick={() => onChange(maxValue)}>
            <span className="number-input__maxValue">{t("bridge.max")}</span>
          </div>
        ) : null}
      </div>
      {error ? <div className="number-input__error">{error}</div> : null}
      {caption ? <div className="number-input__caption">{caption}</div> : null}
    </div>
  );
};
