export const getCoinImage = (symbol: string) => {
  if (symbol === undefined) {
    symbol = "USDT";
  }
  const lowercase = symbol.toLowerCase();
  if (lowercase.includes("usdt")) {
    return "/images/coins/usdt.svg";
  } else if (lowercase.includes("usdc")) {
    return "/images/coins/usdc.svg";
  }
  return undefined;
};
