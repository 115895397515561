import Web3 from "web3";
import { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core"

import "./WalletModal.scss"
import closeImg from "assets/images/close-modal.svg"
import copyImg from "assets/images/copy.svg"
import ethImg from "assets/icons/pool/eth.svg"
import linkIcon from "assets/images/export.svg"
import {copyToClipboard} from "helpers/copyToClipboard";
import {SimpleButton} from "../SimpleButton";
import {setCookie} from "helpers/cookies";

type Props = {
  onClose: () => void
}

export const WalletModal = ({ onClose }: Props): JSX.Element => {
  const { account, deactivate, library } = useWeb3React()
  const [balance, setBalance] = useState("0")

  useEffect(() => {
    const web3 = new Web3(library.provider)
    web3.eth.getBalance(`${account}`, function (err, result) {
      if (err) {
        console.log(err)
      } else {
        setBalance(web3.utils.fromWei(result, "ether"))
      }
    })
  }, [account, library.provider])

  const handleClose = () => {
    onClose()
  }

  const onDisconnect = () => {
    deactivate()
    window.localStorage.removeItem("provider")
    setCookie('logged', 'false', 365)
    handleClose()
  }

  return (
    <div className="wallet-modal-wrapper">
      <div className="wallet-modal-content animate__animated animate__zoomIn animate__faster">
        <h2 className="wallet-modal-content-title">Your Wallet</h2>
        <button onClick={handleClose} className="wallet-modal-content-close">
          <img src={closeImg} alt='close' />
        </button>
        <div className="wallet-modal-content-address-title">Your Address</div>
        <button onClick={() => copyToClipboard(account || "")} className="wallet-modal-content-address-value">
          {account} <img src={copyImg} alt='copy'/>
        </button>
        <div className="wallet-modal-content-block">
          <div className="wallet-modal-content-block-left">
            <img src={ethImg} alt='eth' /> Ethereum
          </div>
          <a
            target="_blank"
            rel="noreferrer noopner"
            href={`https://etherscan.io/address/${account}`}
            className="wallet-modal-content-block-link"
          >
            Etherscan <img src={linkIcon} alt='linl' /> {" "}
          </a>
        </div>
        <div className="wallet-modal-content-block">
          <div className="wallet-modal-content-block-left">ETH Balance</div>
          <div className="wallet-modal-content-block-value">{balance}</div>
        </div>
        <SimpleButton
          text="Disconnect Wallet"
          onClick={onDisconnect}
          variant="border"
        />
      </div>
    </div>
  )
}
