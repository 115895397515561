import {Wrapper, Img, Source, Picture} from "./image.styled";

export const Image = (props) => {
  const {option, alt, width, height, className} = props;

  return (
      <Wrapper className={className}>
        <Picture width={width} height={height} >
          <Source srcSet={`${option?.webp}, ${option?.webp2x} 2x`} />
          <Img src={option?.original} alt={alt} />
        </Picture>
      </Wrapper>
  )
}