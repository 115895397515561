import React, {useEffect, useState} from "react"
import cn from "classnames"
import { useMedia } from "use-media"
import { useWeb3React } from "@web3-react/core"
import {Link, useLocation, useNavigate} from "react-router-dom"

import { connectors } from "helpers/connectors"

import "./Header.scss"
import logoImg from "assets/images/logoBig.svg"
import logoMobileImg from "assets/images/logo.svg"
import menuImg from "assets/images/menu.svg"
import closeImg from "assets/images/close.svg"
import { AccountDropdown } from "../AccountDropdown"
import { NetworkDropdown } from "../NetworkDropdown"
import {useScrollPosition} from "hooks/useScrollPosition";
import {ROUTES} from "common/constants";
import {mixins} from "helpers/mixins";
import {IconPolygon} from "../../assets/icons";
import {WalletModal} from "../WalletModal";
import LanguageSwitcher from "../LanguageSwitcher";
import {getCookie} from "helpers/cookies";
import classNames from "classnames";

type Props = {
  openConnectModal: () => void
}

export const Header = ({ openConnectModal }: Props): JSX.Element => {
  const scroll = useScrollPosition()
  const [sidebar, setSidebar] = useState(false)
  const [wallet, setWallet] = useState(false)
  const { account, activate, chainId } = useWeb3React()
  const isM = useMedia({ maxWidth: mixins.m })
  const navigate = useNavigate()
  const pathname = useLocation().pathname

  useEffect(() => {
    const provider = window.localStorage.getItem("provider")
    const logged = getCookie('logged')
    if (isM && !account && !provider && logged === 'true') {
      // @ts-ignore
      const { ethereum } = window
      if (ethereum) {
        ethereum
          .request({ method: 'eth_requestAccounts' })
          .catch((err: any) => {
            if (err.code === 4001) {
              console.log('Please connect to MetaMask.');
            } else {
              console.error(err);
            }
          });
      }
      activate(connectors.injected)
    }
    if (provider && provider !== "walletConnect") {
      // @ts-ignore
      activate(connectors[provider])
    }
  }, [account, isM, activate])

  const toggleSidebar = () => setSidebar(!sidebar)

  const links = [
    {
      title: "Home",
      link: `${ROUTES.HOME}`,
    },
    {
      title: "Bridge",
      link: `${ROUTES.BRIDGE}`,
    },
    {
      title: "Swap",
      link: `${ROUTES.SWAP}`,
    },
  ]

  return (
    <header
      className={cn("sidebar", {
        show: sidebar,
        offset: scroll > 80,
      })}
    >
      <div className='sidebar-content'>
        <div className={classNames('sidebar-header', {
          logged: !!account
        })}>
          <Link to={ROUTES.HOME} className="sidebar-header-desk">
            <img src={logoImg} alt='logo' className="sidebar-header-logo" />
          </Link>
          <Link to={ROUTES.HOME} className="sidebar-header-mobile">
            <img src={logoMobileImg} alt='logo' className="sidebar-header-logo" />
          </Link>
          {isM && (
            <div className="sidebar-header-right">
              {!!account?.length && <NetworkDropdown account={account} />}
              {!!account?.length ? (
                <AccountDropdown account={account} openWallet={() => setWallet(true)} />
              ) : (
                <button type="button" onClick={openConnectModal} className="sidebar-connect">
                  Connect
                  <div className="sidebar-connect-border" />
                </button>
              )}
              <button className="sidebar-header-btn" onClick={toggleSidebar}>
                <img alt='menu' src={sidebar ? closeImg : menuImg} />
              </button>
            </div>
          )}
        </div>
        {isM && (
          <div
            className={cn("header-sidebar", {
              active: sidebar,
            })}
          >
            {links.map((link, index) =>
              <button
                onClick={() => {
                  navigate(link.link || "")
                  setSidebar(false)
                }}
                key={index}
                className="sidebar-navigation-item"
              >
                {link.title}
                <div className="sidebar-navigation-item-coming">
                  <IconPolygon />
                  Coming Soon...
                </div>
              </button>
            )}
          </div>
        )}
        {!isM && (
          <div className='sidebar-links'>
            {links.map((link, index) =>
              <button
                onClick={() => {
                  navigate(link.link || "")
                  setSidebar(false)
                }}
                key={index}
                className={cn('sidebar-navigation-item', {
                  active: pathname === link.link
                })}
              >
                {link.title}
                <div className='sidebar-navigation-item-line' />
                <div className="sidebar-navigation-item-coming">
                  <IconPolygon />
                  Coming Soon...
                </div>
              </button>
            )}
          </div>
        )}
        {!isM && (
          <div className="sidebar-right">
            {!!account?.length && <NetworkDropdown chainId={chainId} account={account} />}
            {!!account?.length ? (
              <AccountDropdown account={account} openWallet={() => setWallet(true)} />
            ) : (
              <button type="button" onClick={openConnectModal} className="sidebar-connect">
                Connect
                <div className="sidebar-connect-border" />
              </button>
            )}
            <LanguageSwitcher className="actions-item"/>
          </div>
        )}
      </div>
      {wallet && <WalletModal onClose={() => setWallet(false)} />}
    </header>
  )
}
