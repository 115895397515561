const parseInteger = (config: any): number => {
  if (!config) {
    throw new Error("config required");
  }
  return parseInt(config);
};

const notNull = (config?: string): string => {
  if (!config) {
    throw new Error("config required");
  }
  return config;
};

const config = {
  polygon: {
    name: "Polygon",
    symbol: "MATIC",
    rpcUrl: notNull(process.env.REACT_APP_POLYGON_RPC_URL),
    bridge: notNull(process.env.REACT_APP_POLYGON_BRIDGE_CONTRACT),
    networkId: parseInteger(process.env.REACT_APP_POLYGON_NETWORK_ID),
    destination: parseInteger(process.env.REACT_APP_POLYGON_DESTINATION),
  },
  binance: {
    name: "Binance Smart Chain",
    symbol: "BNB",
    rpcUrl: notNull(process.env.REACT_APP_BSC_RPC_URL),
    bridge: notNull(process.env.REACT_APP_BSC_BRIDGE_CONTRACT),
    networkId: parseInteger(process.env.REACT_APP_BSC_NETWORK_ID),
    destination: parseInteger(process.env.REACT_APP_BSC_DESTINATION),
  },
  ethereum: {
    name: "Ethereum",
    symbol: "ETH",
    rpcUrl: notNull(process.env.REACT_APP_ETHEREUM_RPC_URL),
    bridge: notNull(process.env.REACT_APP_ETHEREUM_BRIDGE_CONTRACT),
    networkId: parseInteger(process.env.REACT_APP_ETHEREUM_NETWORK_ID),
    destination: parseInteger(process.env.REACT_APP_ETHEREUM_DESTINATION),
  },
  fantom: {
    name: "Fantom",
    symbol: "FTM",
    rpcUrl: notNull(process.env.REACT_APP_FANTOM_RPC_URL),
    bridge: notNull(process.env.REACT_APP_FANTOM_BRIDGE_CONTRACT),
    networkId: parseInteger(process.env.REACT_APP_FANTOM_NETWORK_ID),
    destination: parseInteger(process.env.REACT_APP_FANTOM_DESTINATION),
  },
  avalanche: {
    name: "Avalanche",
    symbol: "AVAX",
    rpcUrl: notNull(process.env.REACT_APP_AVALANCHE_RPC_URL),
    bridge: notNull(process.env.REACT_APP_AVALANCHE_BRIDGE_CONTRACT),
    networkId: parseInteger(process.env.REACT_APP_AVALANCHE_NETWORK_ID),
    destination: parseInteger(process.env.REACT_APP_AVALANCHE_DESTINATION),
  },
  cronos: {
    name: "CRO",
    rpcUrl: notNull(process.env.REACT_APP_CRONOS_RPC_URL),
    bridge: notNull(process.env.REACT_APP_CRONOS_BRIDGE_CONTRACT),
    networkId: parseInteger(process.env.REACT_APP_CRONOS_NETWORK_ID),
    destination: parseInteger(process.env.REACT_APP_CRONOS_DESTINATION),
  },
  tron: {
    name: "Tron",
    symbol: "TRX",
    rpcUrl: notNull(process.env.REACT_APP_TRON_RPC_URL),
    bridge: notNull(process.env.REACT_APP_TRON_BRIDGE_CONTRACT),
    destination: parseInteger(process.env.REACT_APP_TRON_DESTINATION),
    networkId: 99999,
  },
};

export default config;
