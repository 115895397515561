export interface NetworkTokens {
  [key: number]: { [key: number]: number };
}
// id network:
// 137 - polygon
// 56 - bsc
// 1 - eth
// 250 - fantom
// 43114 - avalanche
// 25 - cronos
// 99999 - tron

export const networkToTokens: NetworkTokens = {
  56: {
    137: 0,
    1: 1,
    250: 2,
    43114: 3,
    25: 4,
    99999: 5,
  },
  137: {
    56: 0,
    1: 1,
    250: 2,
    43114: 3,
    25: 4,
    99999: 5,
  },
  1: {
    137: 0,
    56: 1,
    43114: 2,
    250: 3,
    25: 4,
    99999: 5,
  },
  250: {
    137: 0,
    56: 1,
    1: 2,
    43114: 3,
    25: 4,
    99999: 5,
  },
  25: {
    137: 0,
    56: 1,
    1: 2,
    250: 3,
    43114: 4,
    99999: 5,
  },
  99999: {
    137: 0,
    56: 1,
    1: 2,
    250: 3,
    43114: 4,
    25: 5,
  },
  43114: {
    137: 0,
    56: 1,
    1: 2,
    250: 3,
    99999: 4,
    25: 5,
  },
};
