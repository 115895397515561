import {Wrapper, Card, Num} from "./roadmapitem.styled";
import {StyledFlex} from "common/styled-components/helpers";
import {IconStar} from "assets/icons";
// https://www.react-reveal.com/examples/common/fade/
import Fade from 'react-reveal/Fade';

export const RoadmapItem = (props) => {
  const {options, as, className, num} = props;

  return (
      <Wrapper as={as} reversed={options.reversed} className={className} num={num}>
          <StyledFlex justify="space-between" align="center" direction={options.reversed ? 'row-reverse' : undefined}>
            <Fade left={!options.reversed} right={options.reversed} distance={'10px'} delay={500}>
              <Card>
                <h4>
                  {options.title}
                  <sup> {options.year}</sup>
                </h4>

                <p>{options.description}</p>
              </Card>
            </Fade>

            <Fade left={options.reversed} right={!options.reversed} distance={'20px'} delay={50 * num}>
              <Num>{options.num ? options.num : (<IconStar width="2.4rem" height="2.4rem" fill="currentColor"/>)}</Num>
            </Fade>
          </StyledFlex>
      </Wrapper>
  )
}