import React, { useEffect, useState } from "react"
import Chart from "react-apexcharts"
import { ethers } from "ethers"
import { useWeb3React } from "@web3-react/core"
import {
  limitOrderProtocolAddresses,
  LimitOrderBuilder,
  Web3ProviderConnector,
} from "@1inch/limit-order-protocol-utils"
import axios from "axios"
import Web3 from "web3"
import cn from "classnames"
import toast from "react-hot-toast";
import {useSearchParams} from "react-router-dom";

import {IconExchange} from "assets/icons"
import imgLeftSrc from "assets/images/left.svg"
import imgRightSrc from "assets/images/right.svg"
import { moneyFormatter } from "helpers/moneyFormatter"
import { apiCoin } from "api/apiCoinGecko"
import useDebounce from "hooks/useDebounce"
import { api } from "api/api"
import {
  approveAddress,
  contractAddressApeApprove,
  contractAddressLinkApprove,
  contractAddressUniApprove,
  contractAddressUsdcApprove,
  contractAddressWbtcApprove,
  contractAddressWethApprove,
  contractAddressBusdApprove,
  contractAddressCakeApprove,
  contractAddressBabyDogeApprove,
  contractAddressC98Approve,
  contractAddressTwtApprove,
  contractAddressGmtApprove,
} from "./contractsAddresses"
import abiApprove from "../../web3/abi/approve.json"
import {apiScan} from "api/apiScan";
import {Loading} from "components/Loading";
import {Input} from "components/Input";
import {CoinsDropdown} from "components/CoinsDropdown";
import {SimpleButton} from "components/SimpleButton";
import Footer from "components/Footer";

import "./Swap.scss"
import {ConnectModal} from "../../components/ConnectModal";
import {Header} from "../../components/HeaderSwap";
import {coinsGeckoMock} from "./coinsGecko";
import {coinsInchBsc} from "./coinsInchBsc";
import {coinsInchEth} from "./coinsInchEth";
import {SmallLoading} from "../../components/SmallLoading";

const zeroPad = (num: number, places: number) => String(num).padEnd(places + 1, "0")

const tabsList = [
  {
    title: 'Swap',
    id: 'swap',
  },
  // {
  //   title: 'Limit',
  //   id: 'limit',
  // },
]

const SwapPage = (): JSX.Element => {
  const [search, setSearch] = useSearchParams()
  const [tabs, setTabs] = useState("swap")
  const [tabsChart, setTabsChart] = useState("send")
  const [slippage] = useState(2.5)
  const [prices, setPrices] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const [loadingCoin, setLoadingCoin] = useState(true)
  const [loadingReceive, setLoadingReceive] = useState(true)
  const [loadingSendOne, setLoadingSendOne] = useState(true)
  const [loadingReceiveOne, setLoadingReceiveOne] = useState(true)
  const [connect, setConnect] = useState(false)
  const [sendInput, setSendInput] = useState("1")
  const debouncedSendInput = useDebounce(sendInput, 500)
  const [sendCurrency, setSendCurrency] = useState<any>()
  const debouncedSendCurrency = useDebounce(sendCurrency, 1000)
  const [receiveCurrency, setReceiveCurrency] = useState<any>()
  const debouncedReceiveCurrency = useDebounce(receiveCurrency, 1000)
  const [receiveInput, setReceiveInput] = useState("")
  const [pricePerOneLeft, setPricePerOneLeft] = useState(0)
  const [pricePerOneRight, setPricePerOneRight] = useState(0)
  const [approving, setApproving] = useState(false)
  const [exchanging, setExchanging] = useState(false)
  const { account, library, chainId } = useWeb3React()

  const [txDetails, setTxDetails] = useState({
    to: null,
    data: null,
    value: null,
    gas: null,
    gasPrice: null
  })
  const [stat1, setStat1] = useState<any>(undefined)
  const [stat2, setStat2] = useState<any>(undefined)
  const busd = 1000000000000000000
  const [walletBalanceEth, setWalletBalanceEth] = useState(0)
  const [walletBalanceUsdt, setWalletBalanceUsdt] = useState(0)
  const [walletBalanceUsdc, setWalletBalanceUsdc] = useState(0)
  const [walletBalanceUni, setWalletBalanceUni] = useState(0)
  const [walletBalanceLink, setWalletBalanceLink] = useState(0)
  const [walletBalanceWbtc, setWalletBalanceWbtc] = useState(0)
  const [walletBalanceApe, setWalletBalanceApe] = useState(0)
  const [walletBalanceWeth, setWalletBalanceWeth] = useState(0)
  const [walletBalanceBnb, setWalletBalanceBnb] = useState(0)
  const [walletBalanceBusd, setWalletBalanceBusd] = useState(0)
  const [walletBalanceCake, setWalletBalanceCake] = useState(0)
  const [walletBalanceBaby, setWalletBalanceBaby] = useState(0)
  const [walletBalanceTwt, setWalletBalanceTwt] = useState(0)
  const [walletBalanceGmt, setWalletBalanceGmt] = useState(0)
  const [walletBalanceC98, setWalletBalanceC98] = useState(0)

  const changeValues = () => {
    const firstCurr = sendCurrency
    const firstInput = sendInput
    const secondCurr = receiveCurrency
    const secondInput = receiveInput

    setReceiveCurrency(firstCurr)
    setSendCurrency(secondCurr)
    setReceiveInput(firstInput)
    setSendInput(secondInput)

    search.set('send', secondCurr?.symbol);
    search.set('receive', firstCurr?.symbol);
    setSearch(search)
  }

  useEffect(() => {
    setLoading(true)
    const baseLinkPrices = `/swapi/price/v1.1/${chainId || 1}?currency=USD`
    axios.get(`${baseLinkPrices}`)
            .then((r) => {
              setPrices(r.data)
              setLoading(false)
            })
            .catch(() => {
              setTimeout(() => {
                axios.get(`${baseLinkPrices}`)
                        .then((r) => {
                          setPrices(r.data)
                          setLoading(false)
                        })
                        .catch(() => {
                          setTimeout(() => {
                            axios.get(`${baseLinkPrices}`)
                                    .then((r) => {
                                      setPrices(r.data)
                                    })
                                    .finally(() => {
                                      setLoading(false)
                                    })
                          }, 2000)
                        })
              }, 2000)
            })
            .finally(() => {
              setLoading(false)
            })
  }, [chainId])

  useEffect(() => {
    const send = search.get('send')
    const receive = search.get('receive')

    if (chainId === 56) {
      if (send) {
        setSendCurrency(coinsInchBsc.find(i => i.symbol === send) || coinsInchBsc.find(i => i.symbol === 'BNB'))
      } else {
        setSendCurrency(coinsInchBsc.find(i => i.symbol === 'BNB'))
      }
      if (receive) {
        setReceiveCurrency(coinsInchBsc.find(i => i.symbol === receive) || coinsInchBsc.find(i => i.symbol === 'BUSD'))
      } else {
        setReceiveCurrency(coinsInchBsc.find(i => i.symbol === 'BUSD'))
      }
    } else {
      if (send) {
        setSendCurrency(coinsInchEth.find(i => i.symbol === send) || coinsInchEth.find(i => i.symbol === 'ETH'))
      } else {
        setSendCurrency(coinsInchEth.find(i => i.symbol === 'ETH'))
      }
      if (receive) {
        setReceiveCurrency(coinsInchEth.find(i => i.symbol === receive) || coinsInchEth.find(i => i.symbol === 'USDT'))
      } else {
        setReceiveCurrency(coinsInchEth.find(i => i.symbol === 'USDT'))
      }
    }
    // eslint-disable-next-line
  }, [search, chainId])

  useEffect(() => {
    // @ts-ignore
    if (chainId === 56) {
      setReceiveInput("")
      setSendInput("1")
    } else {
      setReceiveInput("")
      setSendInput("1")
    }
  }, [chainId])

  useEffect(() => {
    setLoadingCoin(true)
    if (debouncedSendCurrency) {
      apiCoin
              .getChartDaily(coinsGeckoMock.find((i: any) => i.platforms?.includes(debouncedSendCurrency?.address))?.id || coinsGeckoMock.find(i => i.symbol === debouncedSendCurrency?.symbol?.toLowerCase())?.id || '')
              .then(rOther => {
                setStat1({
                  options: {
                    colors: [`rgba(241, 145, 1, 1)`],
                    title: {
                      text: `${debouncedSendCurrency?.name}`,
                      align: "left",
                    },
                    chart: {
                      id: "area-datetime",
                      type: "area",
                      zoom: {
                        autoScaleYaxis: true,
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    markers: {
                      size: 0,
                      style: "hollow",
                      colors: [`rgba(241, 145, 1, 1)`]
                    },
                    xaxis: {
                      type: "datetime",
                      tickAmount: 6,
                      labels: {
                        datetimeUTC: false,
                      },
                    },
                    tooltip: {
                      x: {
                        formatter: function (val: string) {
                          return `${new Date(val).toLocaleDateString()} ${new Date(val).toLocaleTimeString()}`
                        },
                      },
                      y: {
                        formatter: function (val: number) {
                          return val.toFixed(8)
                        },
                      },
                    },
                    fill: {
                      type: "gradient",
                      gradient: {
                        shadeIntensity: 2,
                        opacityFrom: 0.7,
                        opacityTo: 1,
                        stops: [0, 100],
                      },
                      colors: [`rgb(252, 182, 82)`]
                    },
                    yaxis: {
                      labels: {
                        formatter: function (val: number) {
                          return val.toFixed(0)
                        },
                      },
                    },
                  },
                  series: [
                    {
                      name: "Price",
                      data: rOther.prices,
                    },
                  ],
                })
              })
    }
    if (debouncedReceiveCurrency) {
      apiCoin
              .getChartDaily(coinsGeckoMock.find((i: any) => i.platforms?.includes(debouncedReceiveCurrency?.address))?.id || coinsGeckoMock.find(i => i.symbol === debouncedReceiveCurrency?.symbol?.toLowerCase())?.id || '')
              .then(rOther => {
                setStat2({
                  options: {
                    colors: [`rgba(241, 145, 1, 1)`],
                    title: {
                      text: `${debouncedReceiveCurrency?.name}`,
                      align: "left",
                    },
                    chart: {
                      id: "basic-bar",
                      type: "area",
                      zoom: {
                        autoScaleYaxis: true,
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    markers: {
                      size: 0,
                      style: "hollow",
                      colors: [`rgba(241, 145, 1, 1)`]
                    },
                    xaxis: {
                      type: "datetime",
                      tickAmount: 6,
                      labels: {
                        datetimeUTC: false,
                      },
                    },
                    tooltip: {
                      x: {
                        formatter: function (val: string) {
                          return `${new Date(val).toLocaleDateString()} ${new Date(val).toLocaleTimeString()}`
                        },
                      },
                    },
                    fill: {
                      type: "gradient",
                      gradient: {
                        shadeIntensity: 2,
                        opacityFrom: 0.7,
                        opacityTo: 1,
                        stops: [0, 100],
                      },
                      colors: [`rgb(252, 182, 82)`]
                    },
                    yaxis: {
                      labels: {
                        formatter: function (val: number) {
                          return val.toFixed(5)
                        },
                      },
                    },
                  },
                  series: [
                    {
                      name: "Price",
                      data: rOther.prices,
                    },
                  ],
                })
              })
              .finally(() => setLoadingCoin(false))
    }
  }, [debouncedSendCurrency, debouncedReceiveCurrency])

  async function decodeTransactionParameters(txHash: string) {
    // @ts-ignore
    const { ethereum } = window

    const provider = new ethers.providers.Web3Provider(ethereum)

    const inter = new ethers.utils.Interface(abiApprove);

    const tx = await provider.getTransaction(txHash);
    const decodedInput = inter.parseTransaction({ data: tx.data, value: tx.value});
    console.log(decodedInput)

    return (Number(decodedInput.args[1]))
  }


  const getAllInfo = () => {
    // @ts-ignore
    const { ethereum } = window
    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(ethereum)
      if (chainId === 1 || !chainId) {
        api.getBalance(`${account}`).then(r => {
          setWalletBalanceEth(Number(r.result) / busd)
        })

        const tokenContractUsdt = new ethers.Contract(approveAddress, abiApprove, provider)
        tokenContractUsdt.balanceOf(account).then((r: any) => {
          setWalletBalanceUsdt(Number(r) / 1000000)
        })
        const tokenContractUsdc = new ethers.Contract(contractAddressUsdcApprove, abiApprove, provider)

        tokenContractUsdc.balanceOf(account).then((r: any) => {
          setWalletBalanceUsdc(Number(r) / 1000000)
        })
        const tokenContractUni = new ethers.Contract(contractAddressUniApprove, abiApprove, provider)

        tokenContractUni.balanceOf(account).then((r: any) => {
          setWalletBalanceUni(Number(r) / busd)
        })
        const tokenContractLink = new ethers.Contract(contractAddressLinkApprove, abiApprove, provider)

        tokenContractLink.balanceOf(account).then((r: any) => {
          setWalletBalanceLink(Number(r) / busd)
        })
        const tokenContract = new ethers.Contract(contractAddressWbtcApprove, abiApprove, provider)

        tokenContract.balanceOf(account).then((r: any) => {
          setWalletBalanceWbtc(Number(r) / 100000000)
        })
        const tokenContractApe = new ethers.Contract(contractAddressApeApprove, abiApprove, provider)

        tokenContractApe.balanceOf(account).then((r: any) => {
          setWalletBalanceApe(Number(r) / busd)
        })
        const tokenContractWeth = new ethers.Contract(contractAddressWethApprove, abiApprove, provider)

        tokenContractWeth.balanceOf(account).then((r: any) => {
          setWalletBalanceWeth(Number(r) / busd)
        })
      }
      if (chainId === 56) {
        apiScan.getBalance(`${account}`).then(r => {
          setWalletBalanceBnb(Number(r.result) / busd)
        })

        const tokenContractBusd = new ethers.Contract(contractAddressBusdApprove, abiApprove, provider)
        tokenContractBusd.balanceOf(account).then((r: any) => {
          setWalletBalanceBusd(Number(r) / busd)
        })
        const tokenContractCake = new ethers.Contract(contractAddressCakeApprove, abiApprove, provider)

        tokenContractCake.balanceOf(account).then((r: any) => {
          setWalletBalanceCake(Number(r) / busd)
        })

        const tokenContractTwt = new ethers.Contract(contractAddressTwtApprove, abiApprove, provider)

        tokenContractTwt.balanceOf(account).then((r: any) => {
          setWalletBalanceTwt(Number(r) / busd)
        })
        const tokenContractBaby = new ethers.Contract(contractAddressBabyDogeApprove, abiApprove, provider)

        tokenContractBaby.balanceOf(account).then((r: any) => {
          setWalletBalanceBaby(Number(r) / 1000000000)
        })

        const tokenContractGmt = new ethers.Contract(contractAddressGmtApprove, abiApprove, provider)

        tokenContractGmt.balanceOf(account).then((r: any) => {
          setWalletBalanceGmt(Number(r) / 100000000)
        })
        const tokenContractCoin = new ethers.Contract(contractAddressC98Approve, abiApprove, provider)

        tokenContractCoin.balanceOf(account).then((r: any) => {
          setWalletBalanceC98(Number(r) / busd)
        })
      }
    }
  }

  useEffect(() => {
    if (account) {
      getAllInfo()
    }
    // eslint-disable-next-line
  }, [account, chainId])

  useEffect(() => {
    // @ts-ignore
    const { ethereum } = window

    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(ethereum)

      provider.getNetwork().then((r) => {
        if (r.chainId !== 1 && r.chainId !== 56) {
          toast.error('Your network is not supported :( Please change it to Ethereum or Binance Smart Chain')
        }
      })
    }
  }, [account])

  useEffect(() => {
    setLoadingSendOne(true)
    const baseLink = `/swapi/swap/v5.2/${chainId || 1}`
    if (debouncedSendCurrency && debouncedReceiveCurrency) {
      axios.get(`${baseLink}/quote`, {params: {
          src: debouncedSendCurrency.address,
          dst: debouncedReceiveCurrency.address,
          amount: Number(zeroPad(1, debouncedSendCurrency.decimals)) || 0,
        }}).then(r => {
        setPricePerOneLeft(Number(r.data.toAmount) / Number(zeroPad(1, debouncedReceiveCurrency.decimals)) || 0)
        setLoadingSendOne(false)
      }).catch(() => {
        setTimeout(() => {
          axios.get(`${baseLink}/quote`, {params: {
              src: debouncedSendCurrency.address,
              dst: debouncedReceiveCurrency.address,
              amount: Number(zeroPad(1, debouncedSendCurrency.decimals)) || 0,
            }}).then(r => {
            setPricePerOneLeft(Number(r.data.toAmount) / Number(zeroPad(1, debouncedReceiveCurrency.decimals)) || 0)
            setLoadingSendOne(false)
          }).catch(() => {
            setTimeout(() => {
              axios.get(`${baseLink}/quote`, {params: {
                  src: debouncedSendCurrency.address,
                  dst: debouncedReceiveCurrency.address,
                  amount: Number(zeroPad(1, debouncedSendCurrency.decimals)) || 0,
                }}).then(r => {
                setPricePerOneLeft(Number(r.data.toAmount) / Number(zeroPad(1, debouncedReceiveCurrency.decimals)) || 0)
              }).catch(() => {
                setPricePerOneLeft(0)
              }).finally(() => setLoadingSendOne(false))
            }, 1500)
          })
        }, 1500)
      })
    }
    // eslint-disable-next-line
  }, [debouncedSendCurrency, debouncedReceiveCurrency])

  useEffect(() => {
    setLoadingReceiveOne(true)
    const baseLink = `/swapi/swap/v5.2/${chainId || 1}`
    if (debouncedSendCurrency && debouncedReceiveCurrency) {
      setTimeout(() => {
        axios.get(`${baseLink}/quote`, {params: {
            src: debouncedReceiveCurrency.address,
            dst: debouncedSendCurrency.address,
            amount: Number(zeroPad(1, debouncedReceiveCurrency.decimals)) || 0,
          }})
          .then(r => {
            setLoadingReceiveOne(false)
            setPricePerOneRight(Number(r.data.toAmount) / Number(zeroPad(1, debouncedSendCurrency.decimals)) || 0)
          })
          .catch(() => {
            setTimeout(() => {
              axios.get(`${baseLink}/quote`, {params: {
                  src: debouncedReceiveCurrency.address,
                  dst: debouncedSendCurrency.address,
                  amount: Number(zeroPad(1, debouncedReceiveCurrency.decimals)) || 0,
                }})
                .then(r => {
                  setLoadingReceiveOne(false)
                  setPricePerOneRight(Number(r.data.toAmount) / Number(zeroPad(1, debouncedSendCurrency.decimals)) || 0)
                })
                .catch(() => {
                  setTimeout(() => {
                    axios.get(`${baseLink}/quote`, {params: {
                        src: debouncedReceiveCurrency.address,
                        dst: debouncedSendCurrency.address,
                        amount: Number(zeroPad(1, debouncedReceiveCurrency.decimals)) || 0,
                      }})
                            .then(r => {
                              setPricePerOneRight(Number(r.data.toAmount) / Number(zeroPad(1, debouncedSendCurrency.decimals)) || 0)
                            })
                            .catch(() => {
                              setPricePerOneRight(0)
                            })
                  }, 2000)
                }).finally(() => setLoadingReceiveOne(false))
            }, 2000)
          })
      }, 2000)
    }
    // eslint-disable-next-line
  }, [debouncedSendCurrency, debouncedReceiveCurrency])

  useEffect(() => {
    setLoadingReceive(true)
    const baseLink = `/swapi/swap/v5.2/${chainId || 1}`
    if (debouncedSendCurrency && debouncedReceiveCurrency) {
      axios.get(`${baseLink}/quote`, {params: {
          src: debouncedSendCurrency.address,
          dst: debouncedReceiveCurrency.address,
          amount: Number(sendInput) * Number(zeroPad(1, debouncedSendCurrency.decimals)) || 0,
        }}).then(r => {
          setLoadingReceive(false)
        setReceiveInput((Number(r.data.toAmount) / Number(zeroPad(1, debouncedReceiveCurrency.decimals))).toString() || "")
      }).catch(() => {
        setTimeout(() => {
          axios.get(`${baseLink}/quote`, {params: {
              src: debouncedSendCurrency.address,
              dst: debouncedReceiveCurrency.address,
              amount: Number(sendInput) * Number(zeroPad(1, debouncedSendCurrency.decimals)) || 0,
            }}).then(r => {
            setLoadingReceive(false)
            setReceiveInput((Number(r.data.toAmount) / Number(zeroPad(1, debouncedReceiveCurrency.decimals))).toString() || "")
          }).catch(() => {
            setTimeout(() => {
              axios.get(`${baseLink}/quote`, {params: {
                  src: debouncedSendCurrency.address,
                  dst: debouncedReceiveCurrency.address,
                  amount: Number(sendInput) * Number(zeroPad(1, debouncedSendCurrency.decimals)) || 0,
                }}).then(r => {
                setReceiveInput((Number(r.data.toAmount) / Number(zeroPad(1, debouncedReceiveCurrency.decimals))).toString() || "")
              })
                      .finally(() => setLoadingReceive(false))
            }, 1500)
          })
        }, 1500)
      })
    }
    setLoading(false)
    if (debouncedSendInput === "0") {
      setReceiveInput("0")
    }
    // eslint-disable-next-line
  }, [debouncedSendInput, debouncedSendCurrency, debouncedReceiveCurrency])

  async function onSwap(alreadyApproved?: boolean, allowance?: number) {
    const baseLink = `/swapi/swap/v5.2/${chainId || 1}`
    console.log(allowance)
    const newAllowance = allowance || 0
    if (alreadyApproved && sendCurrency?.symbol === 'USDT' && newAllowance === 0) {
      setApproving(true)

      axios.get(
        `${baseLink}/approve/transaction?tokenAddress=${sendCurrency.address}&amount=${Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals)) || 0}`,
      )
        .then(res => {
          setTxDetails(res.data)
        })
        .catch(() => {
          setTimeout(() => {
            axios.get(
              `${baseLink}/approve/transaction?tokenAddress=${sendCurrency.address}&amount=${Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals)) || 0}`,
            )
              .then(res => {
                setTxDetails(res.data)
              })
              .catch(() => {
                setApproving(false)
              })
          }, 1000)
        })
      return
    }
    if (alreadyApproved && sendCurrency?.symbol === 'USDT' && newAllowance > 0 && newAllowance <  Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals))) {
      setApproving(true)

      axios.get(
        `${baseLink}/approve/transaction?tokenAddress=${sendCurrency.address}&amount=0`,
      )
        .then(res => {
          setTxDetails(res.data)
        })
        .catch(() => {
          setTimeout(() => {
            axios.get(
              `${baseLink}/approve/transaction?tokenAddress=${sendCurrency.address}&amount=0`,
            )
              .then(res => {
                setTxDetails(res.data)
              })
              .catch(() => {
                setApproving(false)
              })
          }, 1000)
        })
      return
    }
    if (sendCurrency?.symbol !== 'ETH' && sendCurrency?.symbol !== 'BNB' && !alreadyApproved) {
      const allowance = await axios.get(
        `${baseLink}/approve/allowance?tokenAddress=${sendCurrency.address}&walletAddress=${account}`,
      )
      if (sendCurrency?.symbol === 'USDT') {

        if (allowance.data.allowance === '0') {
          setApproving(true)

          axios.get(
            `${baseLink}/approve/transaction?tokenAddress=${sendCurrency.address}&amount=${Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals)) || 0}`,
          )
            .then(res => {
              setTxDetails(res.data)
            })
            .catch(() => {
              setTimeout(() => {
                axios.get(
                  `${baseLink}/approve/transaction?tokenAddress=${sendCurrency.address}&amount=${Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals)) || 0}`,
                )
                  .then(res => {
                    setTxDetails(res.data)
                  })
                  .catch(() => {
                    setApproving(false)
                  })
              }, 1000)
            })
          return
        }
        if (allowance.data.allowance !== '0' && (Number(allowance.data.allowance) < Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals)))) {
          setApproving(true)
          axios.get(
            `${baseLink}/approve/transaction?tokenAddress=${sendCurrency.address}&amount=0`,
          )
            .then(res => {
              setTxDetails(res.data)
            })
            .catch(() => {
              setTimeout(() => {
                axios.get(
                  `${baseLink}/approve/transaction?tokenAddress=${sendCurrency.address}&amount=0`,
                )
                  .then(res => {
                    setTxDetails(res.data)
                  })
                  .catch(() => {
                    setApproving(false)
                  })
              }, 1000)
            })
          return
        }
      } else {
        if (allowance.data.allowance === '0' || (Number(allowance.data.allowance) < Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals)))) {
          setApproving(true)

          axios.get(
            `${baseLink}/approve/transaction?tokenAddress=${sendCurrency.address}&amount=${Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals)) || 0}`,
          )
            .then(res => {
              setTxDetails(res.data)
            })
            .catch(() => {
              setTimeout(() => {
                axios.get(
                  `${baseLink}/approve/transaction?tokenAddress=${sendCurrency.address}&amount=${Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals)) || 0}`,
                )
                  .then(res => {
                    setTxDetails(res.data)
                  })
                  .catch(() => {
                    setApproving(false)
                  })
              }, 1000)
            })
          return
        }
      }
    }
    if (sendCurrency?.symbol !== 'ETH' && sendCurrency?.symbol !== 'BNB' && alreadyApproved && newAllowance < Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals))) {
      setApproving(true)

      axios.get(
        `${baseLink}/approve/transaction?tokenAddress=${sendCurrency.address}&amount=${Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals)) || 0}`,
      )
        .then(res => {
          setTxDetails(res.data)
        })
        .catch(() => {
          setTimeout(() => {
            axios.get(
              `${baseLink}/approve/transaction?tokenAddress=${sendCurrency.address}&amount=${Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals)) || 0}`,
            )
              .then(res => {
                setTxDetails(res.data)
              })
              .catch(() => {
                setApproving(false)
              })
          }, 1000)
        })
      return
    }

    setExchanging(true)

    setTimeout(() => {
      axios.get(
        `${baseLink}/swap?src=${sendCurrency.address}&dst=${
          receiveCurrency.address
        }&amount=${
          Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals))
        }&fromAddress=${account}&slippage=${slippage}`,
      ).then(res => {
        var decimals = Number(`1E${receiveCurrency.decimals}`)
        setReceiveInput((Number(res.data.toAmount) / decimals).toFixed(2))
        setTxDetails(res.data.tx)
      }).catch((err) => {
        setExchanging(false)
        console.log(err)
        if (err?.response?.status === 400) {
          setApproving(true)
          axios.get(
            `${baseLink}/approve/transaction?tokenAddress=${sendCurrency.address}&amount=${Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals)) || 0}`,
          )
            .then(res => {
              setTxDetails(res.data)
            })
            .catch(() => {
              setTimeout(() => {
                axios.get(
                  `${baseLink}/approve/transaction?tokenAddress=${sendCurrency.address}&amount=${Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals)) || 0}`,
                )
                  .then(res => {
                    setTxDetails(res.data)
                  })
                  .catch(() => {
                    setApproving(false)
                  })
              }, 1000)
            })
          return
        }
        toast.error(err.response.data.description)
      })
    }, 1500)
  }
  async function onLimit() {
    const allowance = await axios.get(
      `/swapi/v5.0/${chainId || 1}/approve/allowance?tokenAddress=${sendCurrency.address}&walletAddress=${account}`,
    )

    if (allowance.data.allowance === "0") {
      const approve = await axios.get(
        `/swapi/v5.0/${chainId || 1}/approve/transaction?tokenAddress=${sendCurrency.address}`,
      )

      setTxDetails(approve.data)
      console.log("not approved")
      return
    }

    // @ts-ignore
    const connector = new Web3ProviderConnector(new Web3(library.provider))
    // @ts-ignore
    const contractAddress = limitOrderProtocolAddresses[chainId || 1]
    // @ts-ignore
    const limitOrderBuilder: any = new LimitOrderBuilder(contractAddress, chainId || 1, connector)

    const limitOrder = limitOrderBuilder.buildLimitOrder({
      makerAssetAddress: sendCurrency.address,
      takerAssetAddress: receiveCurrency.address,
      makerAddress: `${sendCurrency.address}`,
      makingAmount: (Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals))).toString(),
      takingAmount: (Number(receiveInput) * Number(zeroPad(1, receiveCurrency.decimals))).toString(),
    })

    const limitOrderTypedData = limitOrderBuilder.buildLimitOrderTypedData(limitOrder)
    // const limitOrderSignature = limitOrderBuilder.buildOrderSignature(`${account}`, limitOrderTypedData)
    const limitOrderHash = limitOrderBuilder.buildLimitOrderHash(limitOrderTypedData)

    console.log(limitOrderHash)
  }

  // eslint-disable-next-line
  const swapTransactions = async () => {
    if (txDetails.to && account) {
      // @ts-ignore
      const web3 = new Web3(library.provider)
      console.log(txDetails)
      if (approving) {
        await toast.promise(
          web3.eth.sendTransaction({
            from: account,
            to: String(txDetails.to),
            data: String(txDetails.data),
            value: String(txDetails.value),
            gasPrice: String(Math.round(Number(txDetails.gasPrice) * 1.3)),
          })
            .then((res) => {
              decodeTransactionParameters(res.logs[0].transactionHash).then((r) => {
                setApproving(false)
                onSwap(true, r)
              })
            })
          ,
          {
            loading: 'Approving...',
            success:<b>Transaction is successfully! ✅</b>,
            error: e => {
              setExchanging(false)
              setApproving(false)

              return <b>{e.message}</b>
            },
          },
        )
      } else {
        await toast.promise(
          web3.eth.sendTransaction({
            from: account,
            to: String(txDetails.to),
            data: String(txDetails.data),
            value: String(txDetails.value),
            gasPrice: String(Math.round(Number(txDetails.gasPrice) * 1.3)),
          }),
          {
            loading: 'Exchanging...',
            success: () => {
              setExchanging(false)
              setApproving(false)
              return <b>Transaction is successfully! ✅</b>
            },
            error: (e: any) => {
              setExchanging(false)
              setApproving(false)

              return <b>{e.message}</b>
            },
          },
        )
      }
    }


  }

  useEffect(() => {
    swapTransactions()
    // eslint-disable-next-line
  }, [txDetails])

  const getWalletBalance = (token: string) => {
    if (token === "ETH") {
      return walletBalanceEth
    }
    if (token === "USDT") {
      return walletBalanceUsdt
    }
    if (token === "USDC") {
      return walletBalanceUsdc
    }
    if (token === "UNI") {
      return walletBalanceUni
    }
    if (token === "LINK") {
      return walletBalanceLink
    }
    if (token === "WBTC") {
      return walletBalanceWbtc
    }
    if (token === "APE") {
      return walletBalanceApe
    }
    if (token === "WETH") {
      return walletBalanceWeth
    }
    if (token === "BUSD") {
      return walletBalanceBusd
    }
    if (token === "BNB") {
      return walletBalanceBnb
    }
    if (token === "CAKE") {
      return walletBalanceCake
    }
    if (token === "BABYDOGE") {
      return walletBalanceBaby
    }
    if (token === "TWT") {
      return walletBalanceTwt
    }
    if (token === "GMT") {
      return walletBalanceGmt
    }
    if (token === "C98") {
      return walletBalanceC98
    }

    return 0
  }
  const onClickMax = () => {
    setSendInput(getWalletBalance(sendCurrency?.symbol || '').toString())
  }

  const getTextForBtn = () => {
    if (approving) {
      return 'Approving...'
    }
    if (exchanging) {
      return 'Exchanging...'
    }

    return 'Exchange Now'
  }

  return (
    <div className="swap-other">
      <img loading='lazy' src={imgRightSrc} className='swap-other-left' alt='background' />
      <img loading='lazy' src={imgLeftSrc} className='swap-other-right' alt='background' />
      {loading && <Loading />}
      <Header openConnectModal={() => setConnect(true)} />
      <div className="swap-other-content">
        <h3 className="swap-other-content-title">Lightning cryptocurrency exchange</h3>
        <div className="swap-other-content-data">
          <div className="swap-other-content-data-tables">
            <div className="swap-other-content-block-tabs">
              <button
                type="button"
                onClick={() => setTabsChart("send")}
                className={cn("swap-other-content-block-tabs-item", {
                  active: tabsChart === "send",
                })}
              >
                {sendCurrency?.name}
              </button>
              <button
                type="button"
                onClick={() => setTabsChart("receive")}
                className={cn("swap-other-content-block-tabs-item", {
                  active: tabsChart === "receive",
                })}
              >
                {receiveCurrency?.name}
              </button>
            </div>
            <div className='swap-other-content-block-chart'>
              {loadingCoin && <SmallLoading />}
              {/*@ts-ignore*/}
              {stat1 !== undefined && tabsChart === "send" && (<Chart options={stat1.options} series={stat1.series} type="area" />)}
              {/*@ts-ignore*/}
              {stat2 !== undefined && tabsChart === "receive" && (<Chart options={stat2.options} series={stat2.series} type="area" />)}
            </div>
          </div>
          <div className="swap-other-content-block">
            <div className="swap-other-content-block-tabs">
              {tabsList.map((i, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => setTabs(i.id)}
                  className={cn("swap-other-content-block-tabs-item", {
                    active: tabs === i.id,
                  })}
                >
                  {i.title}
                </button>
              ))}
            </div>
            <div className="swap-other-content-block-first">
              <div className="swap-other-content-block-first-input">
                <Input
                  isError={Number(sendInput) > getWalletBalance(sendCurrency?.symbol || '') && !!account}
                  className="swap-other-content-block-first-input-f"
                  onChange={(v: string) => setSendInput(v)}
                  value={sendInput}
                  label={
                    <div className="swap-other-content-block-first-label">
                      <div>You sell</div>
                      {account && (
                        <div className="swap-other-content-block-first-label-max">
                          Balance:{" "}
                          {getWalletBalance(sendCurrency?.symbol || '') === 0
                            ? 0
                            : getWalletBalance(sendCurrency?.symbol || '').toFixed(9)}{" "}
                          <button onClick={onClickMax} className="swap-other-content-block-first-label-max-btn">
                            MAX
                          </button>
                        </div>
                      )}
                    </div>
                  }
                  placeholder="1"
                  append={
                    <CoinsDropdown
                      isLimit={tabs === "limit"}
                      currentCoin={sendCurrency}
                      onSelect={newCoin => {
                        search.set('send', newCoin?.symbol)
                        setSearch(search)
                        setSendCurrency(newCoin)
                      }}
                      coins={chainId === 56 ? coinsInchBsc : coinsInchEth}
                    />
                  }
                />
                <div className="swap-other-content-block-first-input-desc">
                  <div>
                    1 {sendCurrency?.symbol || ''} ≈ {loadingSendOne ? 'Loading... ' : moneyFormatter.format(pricePerOneLeft)} {receiveCurrency?.symbol}
                  </div>
                  <div>
                    {(!!prices && sendCurrency?.address)
                            ? moneyFormatter.format(
                                    prices[sendCurrency.address]* Number(sendInput || 0),
                            )
                            : "Loading"}
                  </div>
                </div>
              </div>
              <button onClick={changeValues} disabled={loadingReceive || loadingCoin || loading || loadingSendOne || loadingReceiveOne} className="swap-other-content-block-first-change">
                <IconExchange />
              </button>
              <div className="swap-other-content-block-first-input">
                <Input
                  loading={loadingReceive}
                  className="swap-other-content-block-first-input-d"
                  onChange={v => setReceiveInput(v)}
                  value={receiveInput}
                  label="You buy"
                  append={
                    <CoinsDropdown
                      isLimit={tabs === "limit"}
                      currentCoin={receiveCurrency}
                      onSelect={newCoin => {
                        search.set('receive', newCoin?.symbol)
                        setSearch(search)
                        setReceiveCurrency(newCoin)
                      }}
                      coins={chainId === 56 ? coinsInchBsc : coinsInchEth}
                    />
                  }
                />
                <div className="swap-other-content-block-first-input-desc">
                  <div>
                    1 {receiveCurrency?.symbol} ≈ {loadingReceiveOne ? 'Loading...' : pricePerOneRight} {sendCurrency?.symbol || ''}
                  </div>
                  <div>
                    {(!!prices && receiveCurrency?.address)
                            ? moneyFormatter.format(
                                    prices[receiveCurrency.address]* Number(receiveInput || 0),
                            )
                            : "Loading"}
                  </div>
                </div>
              </div>
            </div>
            {tabs === "swap" ? (
              <>
                {!account && (
                  <SimpleButton
                    onClick={() => setConnect(true)}
                    text="Connect Wallet"
                    className="swap-other-content-block-btn"
                  />
                )}
                {account && (
                  <>
                    {Number(sendInput) <= getWalletBalance(sendCurrency?.symbol || '') ? (
                      <SimpleButton
                        className="swap-other-content-block-btn"
                        onClick={() => onSwap(false)}
                        text={getTextForBtn()}
                        variant="colored"
                        loading={approving || exchanging}
                      />
                    ) : (
                      <SimpleButton
                        className="swap-other-content-block-btn"
                        text="You don't have enough money"
                        variant="yellow"
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {!account && (
                  <SimpleButton
                    onClick={() => setConnect(true)}
                    text="Connect Wallet"
                    className="swap-other-content-block-btn"
                  />
                )}
                {account && (
                  <>
                    {Number(sendInput) <= getWalletBalance(sendCurrency?.symbol || '') ? (
                      <SimpleButton
                        className="swap-other-content-block-btn"
                        onClick={onLimit}
                        text="Limit"
                        variant="colored"
                      />
                    ) : (
                      <SimpleButton
                        className="swap-other-content-block-btn"
                        text="You don't have enough money"
                        variant="yellow"
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
      {connect && <ConnectModal onClose={() => setConnect(false)} />}
    </div>
  )
}

export default SwapPage;
