import React, { useState } from 'react';
import './style.scss';
import { IOrder } from '../../api/order';
import WaitOrder from '../WaitOrder';
import { useTranslation } from 'react-i18next';

interface IProps {
  startDate: Date;
  onViewProgress: (transaction?: IOrder) => void;
}

const Progress = ({ startDate, onViewProgress }: IProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<IOrder>();

  return (
      <WaitOrder
          waitInterval={5}
          waitMaxTime={300}
          startDate={startDate}
          onLoading={(loading) => setLoading(loading)}
          onFound={(transaction) => {
            setOrder(transaction);
            onViewProgress(transaction);
          }}
      >
        <div className="successContainer">
          <div className="successContainer__imageBlock">
            <div className={`successContainer__image ${loading ? 'successContainer__image_rotating' : ''}`}>
              {loading ?
                  <img src="/images/inprogress.svg" alt="success"/> :
                  <img src="/images/success.svg" alt="success"/>
              }
            </div>
          </div>
          <h2>{t('success.transactionInProgress')}</h2>
          <p>{t('success.transactionSubheader')}</p>
          <button className="bridgeContainer__nextBtn" onClick={() => onViewProgress(order)}>
            <span>{t('success.transactionProgress')}</span>
          </button>
        </div>
      </WaitOrder>
  );
};

export default Progress;
