import cn from "classnames"
import './SmallLoading.scss'

type Props = {
  className?: string
  isolatedBlock?: boolean
}

export const SmallLoading = ({ className, isolatedBlock }: Props) => {
  return (
    <div
      className={cn("small-loading", className, {
        isolatedBlock: isolatedBlock,
      })}
    >
      <div className="small-loading-backdrop" />
      <div className='spinner-fast-small-wrapper'>
        <div className="spinner-fast-small">
        </div>
      </div>
    </div>
  )
}