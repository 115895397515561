import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import "./App.scss";
import BridgeDevPage from "./pages/BridgeDevPage";
import HomePage from "./pages/HomePage";
import SwapPage from "./pages/Swap";
import AccountProvider from "./provider/AccountProvider";

function App() {
  return (
    <AccountProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/bridge" element={<BridgeDevPage />} />
          <Route path="/swap" element={<SwapPage />} />
        </Routes>
        <Toaster
          position="top-right"
          toastOptions={{
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          }}
        />
      </BrowserRouter>
    </AccountProvider>
  );
}

export default App;
