import styled from "styled-components";
import {StyledContainer} from "common/styled-components/helpers";
import {screen} from "common/styled-components/helpers";

export const Wrapper = styled.div`${StyledContainer} {
    position: relative;
    border-bottom: 1px solid ${({theme}) => theme.colors.border};
  }

  .logo,
  .actions {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
  }

  .logo {
    left: 1rem;
  }
  
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    
    right: 1rem;

    .actions-item {
      &:not(:first-child) {
        margin-left: 1.5rem;
        
        ${screen()} {
          margin-left: 1rem;
        }
      }
    }
  }
`