import { HttpClient } from "./http-client"

const apiUrl = "https://api.coingecko.com"
if (!apiUrl) {
  throw new Error("API URL env must be set!")
}

export class Api extends HttpClient {
  public constructor() {
    super(apiUrl as string)
  }

  public getStat = () => {
    return this.instance.get<any[], any>(
      `/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en`,
    )
  }

  public getChart = (id: string) => {
    return this.instance.get<any[], any>(
      `/api/v3/coins/${id}/market_chart?vs_currency=usd&days=29&interval=daily`,
    )
  }

  public getChartDaily = (id: string) => {
    return this.instance.get<any[], any>(
      `/api/v3/coins/${id}/market_chart?vs_currency=usd&days=29`,
    )
  }

  public getList = () => {
    return this.instance.get<any[], any>(`/api/v3/coins/list?include_platform=true`)
  }

  public fetch() {
    return this.instance.get("")
  }
}

const apiCoin = new Api()

export { apiCoin }
