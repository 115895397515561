import React, { useRef, useState } from "react"
import cn from "classnames"
import {useWeb3React} from "@web3-react/core";

import {IconArrowDown, IconLogout} from "assets/icons";
import { useClickOutside } from "hooks/useClickOutside"
import "./AccountDropdown.scss"
import {setCookie} from "helpers/cookies";

type Props = {
  account: string
  openWallet: (type: string) => void
}

export const AccountDropdown = ({ account, openWallet }: Props): JSX.Element => {
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { deactivate } = useWeb3React()

  useClickOutside(wrapperRef, () => {
    setOpen(false)
  })

  const toggleDropdown = () => setOpen(!open)

  const onDisconnect = () => {
    deactivate()
    window.localStorage.removeItem("provider")
    setCookie('logged', 'false', 365)
  }

  return (
    <div
      className={cn("account-dropdown", {
        active: open,
      })}
    >
      <button onClick={toggleDropdown} className="account-dropdown-trigger">
        {`${account.slice(0, 2)}...${account.slice(account.length - 4, account.length)}`}
        <IconArrowDown />
      </button>
      <div className="account-dropdown-content">
        <button
          onClick={() => {
            setOpen(false)
            openWallet("wallet")
          }}
          className="account-dropdown-content-item"
        >
          Wallet
        </button>
        {/*<button*/}
        {/*  onClick={() => {*/}
        {/*    setOpen(false)*/}
        {/*    openWallet("transactions")*/}
        {/*  }}*/}
        {/*  className="account-dropdown-content-item"*/}
        {/*>*/}
        {/*  Recent Transactions*/}
        {/*</button>*/}
        <div className="account-dropdown-content-divider" />
        <button onClick={onDisconnect} className="account-dropdown-content-item">
          Disconnect <IconLogout />
        </button>
      </div>
    </div>
  )
}
