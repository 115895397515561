import { useContext, useEffect } from 'react';
import { fetchOrders, IOrder } from '../../api/order';
import AccountContext from '../../provider/AccountContext';

let interval: any;
let intervalExpiredAt: number | undefined;

interface Props {
  children: any;
  orderId: string;
  needRefresh: boolean;
  waitInterval: number;
  waitMaxTime: number;
  onRefresh: (order: IOrder) => void;
}

const RefreshOrder = ({ children, orderId, needRefresh, waitInterval, waitMaxTime, onRefresh }: Props) => {
  const { account, networkId } = useContext(AccountContext);

  const doClearInterval = () => {
    clearInterval(interval);
    interval = undefined;
    intervalExpiredAt = undefined;
  };

  const listenOrder = async (orderId: string, account: string, networkId: number): Promise<void> => {
    if (intervalExpiredAt && intervalExpiredAt < new Date().getTime()) {
      doClearInterval();
      return;
    }
    try {
      const response = await fetchOrders(account, networkId, 1, 5);
      if (response.data.data.length > 0) {
        for (const order of response.data.data) {
          if (order.id === orderId) {
            if (order.status === 'COMPLETED' || order.status === 'CLOSED' || order.status === 'FAILED') {
              doClearInterval();
              onRefresh(order);
              return;
            } else {
              onRefresh(order);
            }
          }
        }
      }
    } catch (e) {
    }
  };

  useEffect(() => {
    if (needRefresh && account && networkId && !interval) {
      intervalExpiredAt = new Date().getTime() + waitMaxTime * 1000;
      interval = setInterval(() => listenOrder(orderId, account, networkId), waitInterval * 1000);
    }
    return () => doClearInterval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, networkId, needRefresh, orderId]);

  return children;
};

export default RefreshOrder;