import React, { useContext, useEffect, useState } from "react";
import "./History.scss";
import { getStatusColor, getStatusName } from "../../helpers/status";
import { fetchOrders, IPage, IOrder } from "../../api/order";
import AccountContext from "../../provider/AccountContext";
import { getCoinImage } from "../../helpers/coins";
import PaginationPanel from "../PaginationPanel";
import { toAmountFromString } from "../../helpers/display";
import { useTranslation } from "react-i18next";

interface Props {
  onClose: () => void;
  onHistorySelect: (order: IOrder) => void;
}

const PAGE_SIZE = 5;

const History = ({ onClose, onHistorySelect }: Props) => {
  const { t } = useTranslation();
  const { account, networkId } = useContext(AccountContext);

  const [orders, setOrders] = useState<IOrder[]>([]);
  const [page, setPage] = useState<IPage>();

  useEffect(() => loadTransaction(1, account, networkId), [account, networkId]);

  const loadTransaction = (page: number, account?: string, networkId?: number) => {
    if (account && networkId) {
      fetchOrders(account, networkId, page, PAGE_SIZE)
        .then((response) => {
          const new_response = response.data.data.map((order) => {
            order.token = "USDT";
            return order;
          });
          setPage(response.data.meta);
          setOrders(new_response);
        })
        .catch(() => {
          setPage(undefined);
          setOrders([]);
        });
    }
  };

  return (
    <div className="historyContainer">
      <div className="header__block">
        <h1>{t("history.header")}</h1>
        <button onClick={() => onClose()} className="button button_close">
          <img src="/images/close.svg" alt="close" />
        </button>
      </div>
      <div className="historyContainer__tableContainer">
        <div className="historyContainer__table">
          <div className="historyContainer__tableHeader">
            <div>{t("history.token")}</div>
            <div>{t("history.amount")}</div>
            <div>{t("history.from")}</div>
            <div>{t("history.to")}</div>
            <div>{t("history.id")}</div>
            <div>{t("history.status")}</div>
          </div>
          {orders.map((order, index) => {
            return (
              <div onClick={() => onHistorySelect(order)} key={index} className="historyContainer__tableRow">
                <div className="historyContainer__token">
                  <img src={getCoinImage(order.token)} alt="" />
                  {order.token}
                </div>
                <div className="historyContainer__amount">{toAmountFromString(order.amount)}</div>
                <div className="historyContainer__from">{order.fromClient.network}</div>
                <div className="historyContainer__to">{order.toClient.network}</div>
                <div className="historyContainer__id">
                  <span className="historyContainer__idBillet">{order.id}</span>
                </div>
                <div className="historyContainer__status">
                  <span
                    className="historyContainer__statusCircle"
                    style={{ backgroundColor: getStatusColor(order.status) }}
                  />
                  {getStatusName(order.status)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {page && (
        <div className="historyContainer__pagination">
          <PaginationPanel {...page} maxButtons={3} onPage={(page) => loadTransaction(page, account, networkId)} />
        </div>
      )}
    </div>
  );
};

export default History;
