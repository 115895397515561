import WalletConnectProvider from '@walletconnect/web3-provider';
import config from '../config/config';
import { EvmProvider } from '../providers/evm.provider';
import { IProvider } from '../providers/web3.provider';

const rpcMap = Object.values(config).reduce((totalConfig: any, config) => {
  totalConfig[config.networkId] = config.rpcUrl;
  return totalConfig;
}, {});

const walletConnectProvider = new WalletConnectProvider({
  rpc: rpcMap,
});

export const connectWalletConnect = async (): Promise<IProvider> => {
  try {
    await walletConnectProvider.enable();
  } catch (error) {
    return Promise.reject({ code: 'unknown', message: error });
  }
  return new EvmProvider(walletConnectProvider);
};