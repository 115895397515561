export const getStatusColor = (status: 'NEW' | 'CREATED' | 'COMPLETED' | 'CLOSED' | 'FAILED') => {
  switch (status) {
    case 'NEW':
    case 'CREATED':
      return '#DFE30C';
    case 'COMPLETED':
    case 'CLOSED':
      return '#0CE355';
    case 'FAILED':
      return '#E30C0C';
  }
};

export const getStatusName = (status: 'NEW' | 'CREATED' | 'COMPLETED' | 'CLOSED' | 'FAILED') => {
  switch (status) {
    case 'NEW':
    case 'CREATED':
      return 'Pending';
    case 'COMPLETED':
    case 'CLOSED':
      return 'Completed';
    case 'FAILED':
      return 'Failed';
  }
};