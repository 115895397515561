import React from 'react';
import '../History/History.scss';
import { getStatusColor, getStatusName } from '../../helpers/status';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { toAddress, toAmountFromString, toDate } from '../../helpers/display';
import { IOrder } from '../../api/order';
import RefreshOrder from '../RefreshOrder';
import { useTranslation } from 'react-i18next';

interface Props {
  order: IOrder;
  onUpdate: (order: IOrder) => void;
  onClose: () => void;
}

const HistoryDetails = ({ order, onUpdate, onClose }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  return (
      <RefreshOrder
          orderId={order.id}
          needRefresh={order.status === 'NEW' || order.status === 'CREATED'}
          waitInterval={5}
          waitMaxTime={300}
          onRefresh={(order) => onUpdate(order)}
      >
        <div className="historyContainer">
          <div className="header__block">
            <h1>{t('history.order')} #{order.id}</h1>
            <button onClick={() => onClose()} className="button button_close">
              <img src="/images/close.svg" alt="close"/>
            </button>
          </div>
          <div className="historyContainer__transationInfo">
            <div className="historyContainer__transactionInfoRow">
              <div>{t('history.status')}</div>
              <div className="historyContainer__status">
                <span className="historyContainer__statusCircle"
                      style={{ backgroundColor: getStatusColor(order.status) }}/>
                {getStatusName(order.status)}
              </div>
            </div>
            <div className="historyContainer__transactionInfoRow">
              <div>{t('history.transactionId')}</div>
              <div className="historyContainer__idBillet" title={order.txid}>
                {order.txid}
              </div>
            </div>
            <div className="historyContainer__transactionBody historyContainer__transactionBody_progress">
              <div className="historyContainer__transactionInfoRow">
                <div className="historyContainer__depositAccepted">
                  <span className="historyContainer__completeCircle"/>
                  {t('history.depositAccepted')}
                </div>
                <div>
                  <span>{toDate(order.acceptedAt)}</span>
                </div>
              </div>
              <div className="historyContainer__transactionInfoRow historyContainer__transactionBody_progress">
                <div className="historyContainer__depositConfirmed">
                  {order.confirmedAt ?
                      <span className="historyContainer__completeCircle"/> :
                      <span className="historyContainer__progressCircle"/>
                  }
                  {t('history.depositConfirmed')}
                </div>
                <div>
                  <span>{toDate(order.confirmedAt)}</span>
                </div>
              </div>
              <div className="historyContainer__transactionInfoRow historyContainer__transactionBody_progress">
                <div className="historyContainer__depositConfirmed">
                  {order.withdrawnAt ?
                      <span className="historyContainer__completeCircle"/> :
                      order.confirmedAt ?
                          <span className="historyContainer__progressCircle"/> :
                          <span className="historyContainer__waitCircle"/>

                  }
                  {t('history.withdrawalSent')}
                </div>
                <div>
                  <span>{toDate(order.withdrawnAt)}</span>
                </div>
              </div>
              <div className="historyContainer__transactionInfoRow historyContainer__transactionBody_progress">
                <div className="historyContainer__withdrawalCompleted">
                  {order.completedAt ?
                      <span className="historyContainer__completeLastCircle"/> :
                      order.withdrawnAt ?
                          <span className="historyContainer__progressCircle"/> :
                          <span className="historyContainer__waitCircle"/>
                  }
                  {t('history.withdrawalCompleted')}
                </div>
                <div>
                  <span>{toDate(order.completedAt)}</span>
                </div>
              </div>
            </div>
            <div className="historyContainer__transactionInfoRow">
              <div>{t('history.from')}</div>
              <div>
                {toAmountFromString(order.totalAmount)}
                {' '}
                {order.token}
                {' - '}
                {order.fromClient.network}
              </div>
            </div>
            <div className="historyContainer__transactionInfoRow">
              <div>{t('history.to')}</div>
              <div>
                {toAmountFromString(order.amount)}
                {' '}
                {order.token}
                {' - '}
                {order.toClient.network}
              </div>
            </div>
            <div className="historyContainer__transactionInfoRow">
              <div>{t('history.recipientAddress')}</div>
              <div>{toAddress(order.toClient.address, isMobile)}</div>
            </div>
          </div>
        </div>
      </RefreshOrder>
  );
};

export default HistoryDetails;
