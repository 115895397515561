import React from 'react';
import { StyledContainer, StyledFlex } from 'common/styled-components/helpers';
import "./style.scss";

const Footer = () => {
    return (
        <footer className="footer">
          <StyledContainer className="footer__container">
            <StyledFlex align="center" justify="center">
              <div className="footer__actions">
                <a className="footer__link" href="https://t.me/XROWinc" target="_blank" rel="noreferrer">
                  <img src="/images/telegram.svg" alt="telegram-img"/>
                </a>
                <a className="footer__link" href="https://twitter.com/XROWinc" target="_blank" rel="noreferrer">
                  <img src="/images/twitter.svg" alt="twitter-img"/>
                </a>
              </div>

              <ul className="footer__list footer-list">
                <li className="footer-list__item">
                  <a
                      href="/xrow-whitepaper.pdf"
                      className="footer-list__link"
                      target="_blank"
                      rel="noreferrer"
                  >
                    Whitepaper
                  </a>
                </li>

                <li className="footer-list__item">
                  <a
                      href="https://bscscan.com/token/0x7Cc1c126bE3128C1f0441a893Cd6220498B27650"
                      className="footer-list__link"
                      target="_blank"
                      rel="noreferrer"
                  >
                    Blockchain Explorer
                  </a>
                </li>
              </ul>
            </StyledFlex>
          </StyledContainer>
        </footer>
    )
}

export default Footer;
