import config from './config';

let bridgeMap: { [key: string]: string };
let networkMap: { [key: string]: any };
let destinationMap: { [key: string]: number };

export const getNetwork = (networkId: number) => {
  if (!networkMap) {
    networkMap = {};
    for (const c of Object.values(config)) {
      networkMap[c.networkId] = c;
    }
  }
  return networkMap[networkId];
};

export const getBridge = (networkId: number) => {
  if (!bridgeMap) {
    bridgeMap = {};
    for (const c of Object.values(config)) {
      bridgeMap[c.networkId] = c.bridge;
    }
  }
  return bridgeMap[networkId];
};

export const getDestination = (networkId: number) => {
  if (!destinationMap) {
    destinationMap = {};
    for (const c of Object.values(config)) {
      destinationMap[c.networkId] = c.destination;
    }
  }
  return destinationMap[networkId];
};

export const isTronNetwork = (networkId: number) => {
  return networkId === config.tron.networkId;
}
