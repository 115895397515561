import React, {useState} from 'react';
// import { Navbar } from '../NavBar';
import Footer from '../Footer';
import { StyledFlex } from 'common/styled-components/helpers';
import { Header } from 'components/HeaderSwap';
import {ConnectModal} from "../ConnectModal";

interface Props {
  children: any;
  onWalletConnectClick: () => void;
  className?: string;
}

export const Layout = ({ children, onWalletConnectClick, className }: Props) => {
  const [connect, setConnect] = useState(false)

  // @ts-ignore
  return (
      <>
        <Header openConnectModal={() => setConnect(true)} />
          <div className={className}>
              <StyledFlex direction='column' style={{flexGrow: '1'}}>
                  {children}
              </StyledFlex>
          </div>
        {connect && <ConnectModal onClose={() => setConnect(false)} />}
        <Footer/>
      </>
  );
};
