import {Wrapper, TitleTag} from "./title.styled";
import {HashTag} from "../HashTag/HashTag";

export const Title = (props) => {
  const {options, center, className} = props;

  return (
      <Wrapper center={center} className={className}>
        <HashTag>{options?.tag}</HashTag>
        <TitleTag>{options?.title}</TitleTag>
      </Wrapper>
  )
}