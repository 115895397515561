import React, { useContext, useEffect, useMemo, useState } from "react";
import { Option } from "../../Dropdown";
import { NumberInput } from "../../NumberInput";
import { allowance, approve, createOrder } from "../../../web3/web3.service";
import { TextInput } from "../../TextInput";
import Spinner from "../../Spinner";
import AccountContext from "../../../provider/AccountContext";
import If from "../../If";
import bigDecimal from "js-big-decimal";
import { useTranslation } from "react-i18next";
import Web3 from "web3";
import { Modal } from "../../../componentsNew";

enum StepBridge {
  APPROVE = "approveBtn",
  SEND = "sendBtn",
}

interface Props {
  sourceNetwork?: Option;
  targetNetwork?: Option;
  selectedTargetToken?: Option;
  sourceToken?: Option;
  tokenBalance: bigDecimal;
  balanceWithFee: bigDecimal;
  bridgeBalance: bigDecimal;
  tokenAmount?: bigDecimal;
  receiptAddress?: string;
  onReceiptAddressChange: (value?: string) => void;
  receiveAmount?: bigDecimal;
  onReceiveAmountChange: (amount?: bigDecimal, calc?: boolean) => void;
  onBack: () => void;
  onComplete: (transactionHash: string) => void;
  onError: () => void;
}

const SetReceiptAddress = (props: Props) => {
  const { t } = useTranslation();
  const {
    sourceNetwork,
    targetNetwork,
    selectedTargetToken,
    sourceToken,
    tokenBalance,
    balanceWithFee,
    bridgeBalance,
    tokenAmount,
    receiptAddress,
    onReceiptAddressChange,
    receiveAmount,
    onReceiveAmountChange,
    onBack,
    onComplete,
    onError,
  } = props;

  const { account } = useContext(AccountContext);
  // eslint-disable-next-line
  const [invalidAddress, setInvalidAddress] = useState<boolean>(false);
  const [approveRequired, setApproveRequired] = useState<boolean>(true);
  const [processing, setProcessing] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<string>(StepBridge.APPROVE);
  const [allowanceValue, setAllowanceValue] = useState<number>(0);

  useEffect(() => {
    if (!allowanceValue && sourceNetwork && sourceToken && account) {
      allowance(sourceNetwork.args.id, sourceToken.args.token, account)
        .then((allowance) => {
          setInvalidAddress(false);
          setAllowanceValue(allowance / 10 ** sourceToken.args.token.decimals);
          setApproveRequired(
            receiveAmountValueChange(receiveAmount) !== undefined &&
              allowanceValue < receiveAmountValueChange(receiveAmount)
          );
        })
        .catch(() => {
          setInvalidAddress(true);
        });
    } else {
      setApproveRequired(
        receiveAmountValueChange(receiveAmount) !== undefined &&
          allowanceValue < receiveAmountValueChange(receiveAmount)
      );
    }
  }, [sourceNetwork, account, sourceToken, receiveAmount, allowanceValue]);

  const receiveAmountValueChange = (amount: bigDecimal | undefined): number => {
    if (amount === undefined) {
      return NaN;
    }
    return parseFloat(amount?.getValue().toString());
  };

  const handlerOnValidAddress = (address: string): boolean => {
    const WAValidator = require("multicoin-address-validator");
    const validRes =
      targetNetwork?.value === "TRON" ? WAValidator.validate(address, "Tron", "prod") : Web3.utils.isAddress(address);

    return validRes;
  };

  const handleOnApprove = async () => {
    if (
      !sourceNetwork ||
      !targetNetwork ||
      !selectedTargetToken ||
      !sourceToken ||
      !receiptAddress ||
      !tokenAmount ||
      !account ||
      !sourceToken
    ) {
      throw new Error("No required data");
    }
    if (sourceNetwork?.value === "ETHEREUM" && allowanceValue < receiveAmountValueChange(receiveAmount)) {
      setShowModal(true);
    } else {
      setProcessing(true);
      setCurrentStep(t("bridge.approveBtn"));
      approve(sourceNetwork.args.id, sourceToken.args.token, account, tokenAmount)
        .then(() => {
          setProcessing(false);
          setApproveRequired(false);
        })
        .catch(() => setProcessing(false));
    }
  };

  const handleOnSend = async () => {
    if (!sourceNetwork || !targetNetwork || !sourceToken || !receiptAddress || !tokenAmount || !account) {
      throw new Error("No required data");
    }
    setCurrentStep(t("bridge.sendBtn"));
    setProcessing(true);
    createOrder(
      sourceNetwork.args.id,
      account,
      sourceToken?.args.token,
      receiptAddress,
      targetNetwork.args.id,
      tokenAmount,
      (transactionHash) => onComplete(transactionHash),
      () => {
        setProcessing(false);
        onError();
      }
    ).catch(() => {
      setProcessing(false);
      onError();
    });
  };

  let error = undefined;
  if (sourceToken) {
    const {
      args: {
        token: { minAmount, maxAmount },
      },
    } = sourceToken;
    if (
      receiveAmount !== undefined &&
      (receiveAmount.compareTo(minAmount) < 0 || receiveAmount.compareTo(maxAmount) > 0)
    ) {
      error = t("bridge.tokenAmountError", { min: minAmount.getValue(), max: maxAmount.getValue() });
    }
  }

  const isInsufficientFunds = useMemo(
    () => tokenAmount && tokenBalance.compareTo(tokenAmount) < 0,
    [tokenAmount, tokenBalance]
  );

  const isNotEnoughLiquidity = useMemo(
    () => receiveAmount && bridgeBalance.compareTo(receiveAmount) < 0,
    [receiveAmount, bridgeBalance]
  );

  return (
    <React.Fragment>
      {showModal ? (
        <Modal
          close={setShowModal}
          currentStep={currentStep}
          selectedTargetToken={selectedTargetToken}
          sourceNetwork={sourceNetwork}
          sourceToken={sourceToken}
          account={account}
        />
      ) : undefined}
      <TextInput
        value={receiptAddress}
        label={t("bridge.recipientAddress")}
        placeholder={t("bridge.enterAddress")}
        onChange={(receiptAddress) => onReceiptAddressChange(receiptAddress)}
        error={handlerOnValidAddress(receiptAddress as string) ? undefined : "Invalid address"}
      />
      <NumberInput
        value={receiveAmount}
        maxValue={balanceWithFee}
        label={t("bridge.recipientAmount")}
        placeholder={t("bridge.enterAmount")}
        onChange={(receiveAmount) => onReceiveAmountChange(receiveAmount, true)}
        error={error ? error : !receiveAmount ? t("bridge.required") : undefined}
        caption={`${t("bridge.commission")}: ${sourceToken?.args.token.feeBase.getValue()} ${
          sourceToken?.args.token.symbol
        } + ${sourceToken?.args.token.fee.getValue()}%`}
      />
      <div className="bridgeContainer__buttons">
        <button onClick={() => onBack()} className="bridgeContainer__backBtn">
          <span>{t("bridge.backBtn")}</span>
        </button>
        <button
          onClick={() => (approveRequired ? handleOnApprove() : handleOnSend())}
          className="bridgeContainer__nextBtn"
          disabled={
            !receiptAddress ||
            !tokenAmount ||
            !receiveAmount ||
            !!error ||
            !handlerOnValidAddress(receiptAddress) ||
            isInsufficientFunds ||
            isNotEnoughLiquidity
          }
        >
          <span>
            {isNotEnoughLiquidity
              ? t("bridge.notEnoughLiquidity")
              : isInsufficientFunds
              ? t("bridge.insufficientFunds")
              : approveRequired
              ? t("bridge.approveBtn")
              : t("bridge.sendBtn")}
          </span>
          <If condition={processing}>
            <div className="bridgeContainer__loading">
              <Spinner size={16} color={"#ffffff"} />
            </div>
          </If>
        </button>
      </div>
    </React.Fragment>
  );
};

export default SetReceiptAddress;
