import React, { useState } from 'react';
import './HomePage.scss';
import { useNavigate } from 'react-router-dom';
import ContactForm from '../../components/ContactForm';
import { Layout } from '../../components/Layout/Layout';

import { useTranslation } from 'react-i18next';
import { ROUTES } from 'common/constants';
import {
  coin,
  coinWebp,
  coinWebp2x,
  token,
  tokenWebp,
  tokenWebp2x,
  table,
  tableWebp,
  tableWebp2x
} from 'assets/images';
import { StyledFlex } from 'common/styled-components/helpers';
import { Section, Image, Title, RoadmapItem, FeatureItem } from 'componentsNew';
import { Info } from 'modules';
// https://react-scroll-parallax.damnthat.tv/docs/examples/scroll-effects
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
// @ts-ignore https://sakalx.github.io/react-glitch-effect/
import GlitchText from 'react-glitch-effect/core/GlitchText';
// https://www.react-reveal.com/examples/common/fade/
// @ts-ignore
import Fade from 'react-reveal/Fade';
// @ts-ignore
import Reveal from 'react-reveal/Reveal';

type LoginModel = {
  email: string;
  name: string;
};

const HomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleLogin = (credentials: LoginModel) => {
    console.log('credentials-->', credentials);
    setLoading(true);
  };

  const roadmapsData = [
    {
      year: 2022,
      title: t('roadmaps.1.title'),
      description: t('roadmaps.1.descr'),
      num: 1,
      reversed: false,
    },
    {
      year: 2023,
      title: t('roadmaps.2.title'),
      description: t('roadmaps.2.descr'),
      num: 2,
      reversed: true,
    },
    {
      year: 2023,
      title: t('roadmaps.3.title'),
      description: t('roadmaps.3.descr'),
      num: 3,
      reversed: false,
    },
    {
      year: 2023,
      title: t('roadmaps.4.title'),
      description: t('roadmaps.4.descr'),
      num: 4,
      reversed: true,
    },
    {
      year: 2023,
      title: t('roadmaps.5.title'),
      description: t('roadmaps.5.descr'),
      num: 5,
      reversed: false,
    },
    {
      year: 2024,
      title: t('roadmaps.6.title'),
      description: t('roadmaps.6.descr'),
      num: 6,
      reversed: true,
    },
    {
      year: 2025,
      title: t('roadmaps.7.title'),
      description: t('roadmaps.7.descr'),
      num: false,
      reversed: false,
    },
  ];

  const featuresData = [
    {
      label: t('feature.1'),
      percentage: 40,
      color: '#840DE3',
    },
    {
      label: t('feature.2'),
      percentage: 20,
      color: '#FF9900',
    },
    {
      label: t('feature.3'),
      percentage: 15,
      color: '#C25471'
    },
    {
      label: t('feature.4'),
      percentage: 15,
      color: '#272727'
    },
    {
      label: t('feature.5'),
      percentage: 10,
      color: '#FFFFFF'
    }
  ];

  const infoData = {
    xrowToken: {
      tag: t('info.xrowToken.tag'),
      title: t('info.xrowToken.title'),
      descr: t('info.xrowToken.descr'),
      btn: t('info.xrowToken.btn'),
      icon: 'IconSwap',
      link: 'https://pancakeswap.finance/swap?outputCurrency=0x7cc1c126be3128c1f0441a893cd6220498b27650&chainId=56',
      target: '_blank'
    },
    crossChain: {
      tag: t('info.crossChain.tag'),
      title: t('info.crossChain.title'),
      descr: t('info.crossChain.descr'),
      btn: t('info.crossChain.btn'),
      to: ROUTES.BRIDGE
    }
  };

  return (
      <Layout onWalletConnectClick={() => navigate('/bridge')}>
        <ParallaxProvider>
          <Section className="intro">
            <div className="decor-left"></div>
            <div className="decor-right"></div>

            <StyledFlex justify="center" align="center" direction="column">
              <Fade bottom distance={'20px'}>
                <GlitchText component='h1' duration={2000} color1="#850CE3" color2="#E48900">{t('intro.title')}</GlitchText>
                <h3>{t('intro.subtitle')}</h3>
                <p>{t('intro.descr')}</p>
              </Fade>
            </StyledFlex>
          </Section>

          <Section className="info info--lighten">
            <StyledFlex align="center">
              <Info className="info-item info-card" options={infoData.xrowToken} />

              <StyledFlex align="center" justify="center" className="image-wrapper">
                <Image
                    option={{original: coin, webp: coinWebp, webp2x: coinWebp2x}}
                    width="24rem"
                    height="24rem"
                    className="info-item info-item--decor"
                />
              </StyledFlex>
            </StyledFlex>
          </Section>

          <Section className="info info--reverse">
            <StyledFlex align="center" justify="space-between" className="wrapper">
              <Image
                  option={{original: table, webp: tableWebp, webp2x: tableWebp2x}}
                  width="48rem"
                  height="48rem"
                  className="info-item info-item--table"
              />

              <Info options={infoData.crossChain} className="info-item info-card"/>
            </StyledFlex>
          </Section>

          <Section className="roadmaps">
            <Fade bottom distance={'20px'}>
              <Title options={{tag: t('title.roadmap.tag'), title: t('title.roadmap.label')}} center className="main-title"/>
            </Fade>

            <ul className="roadmaps-list">
              {roadmapsData.map((option: any, index: number) => (
                      <Reveal key={`${index}-${option.title}`} effect="is-animate roadmaps-item">
                        <RoadmapItem as="li" options={option} num={index + 1}/>
                      </Reveal>
                  )
              )}
            </ul>
          </Section>

          <Section className="features" size="xl">
            <Title options={{tag: t('title.tokenomics.tag'), title: t('title.tokenomics.label')}} center className="main-title"/>
            <StyledFlex align="center" justify="space-around">
              <Parallax rotate={[0, 360]}>
                <Image
                    option={{original: token, webp: tokenWebp, webp2x: tokenWebp2x}}
                    width="48rem"
                    height="48rem"
                    className="info-item"
                />
              </Parallax>
              <ul className="features-list">
                {featuresData.map((option: any, index: number) =>
                    <FeatureItem key={`${index}-${option.label}`} options={option} as="li" className="features-item"/>
                )}
              </ul>
            </StyledFlex>
          </Section>

          <Section className="faq" size="xl">
            <Title options={{tag: t('title.faq.tag'), title: t('title.faq.label')}} center className="main-title"/>
            <ContactForm onSubmit={handleLogin} loading={loading}></ContactForm>
          </Section>
        </ParallaxProvider>
      </Layout>
  );
};

export default HomePage;
