import { displayAlert } from './alert';

export const handleMetaMaskErrors = (error: any) => {
  if (error === 'metamask_uninstall') {
    displayAlert('Install MetaMask');
  } else if (error === 'no_networks') {
    displayAlert('No networks to exchange');
  } else {
    console.error(error);
  }
}

export const handleBinanceSmartChainError = (error: any) => {
  if (error === 'binance_uninstall') {
    displayAlert('Install Binance Smart Chain extension');
  } else {
    console.error(error);
  }
}

export const handleTronLinkError = (error: any) => {
  if (error === 'tronlink_lock') {
    displayAlert('Unlock TronLink wallet');
  } else if (error === 'tronlink_uninstall') {
    displayAlert('Install TronLink extension');
  } else {
    console.error(error);
  }
}