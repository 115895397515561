import { HttpClient } from "./http-client"

const apiUrl = "https://api.etherscan.io"
if (!apiUrl) {
  throw new Error("API URL env must be set!")
}

export class Api extends HttpClient {
  public constructor() {
    super(apiUrl as string)
  }

  public getBalance = (address: string) => {
    return this.instance.get<any[], any>(
      `/api?module=account&action=balance&address=${address}&apikey=QM4BUKJ3B3QH53Z9JJ983E73QA42FTZ9XS&tag=latest`,
    )
  }

  public fetch() {
    return this.instance.get("")
  }
}

const api = new Api()

export { api }
