import React from 'react';
import { Connectors } from '../web3/web3.connector';

export default React.createContext<{
  loading: boolean,
  account?: string,
  networkId?: number,
  connect: (id: Connectors) => Promise<any>,
  connectTo: (id: Connectors, network: any) => Promise<any>,
  disconnect: () => void;
}>({
  loading: false,
  connect: () => Promise.reject({}),
  connectTo: () => Promise.reject({}),
  disconnect: () => {
  },
});
