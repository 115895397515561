import { IProvider } from '../providers/web3.provider';
import { EvmProvider } from '../providers/evm.provider';

export const connectBinanceSmartChain = async (): Promise<IProvider> => {
  if (typeof window.BinanceChain !== 'undefined') {
    let binanceChainProvider = window.BinanceChain;
    try {
      await binanceChainProvider.request({ method: 'eth_requestAccounts' });
    } catch (error) {
      return Promise.reject(error);
    }
    return new EvmProvider(binanceChainProvider);
  } else {
    return Promise.reject('binance_uninstall');
  }
};