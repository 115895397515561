import { useEffect, useState } from 'react';

const useDeviceDetect = () => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 767) {
      setMobile(true);
    } else setMobile(false);
  }, []);

  return { isMobile };
};

export default useDeviceDetect;