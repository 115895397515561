import React, { useContext, useEffect, useState } from "react";
import "./Bridge.scss";
import { Option } from "../Dropdown";
import SelectNetwork from "./steps/SelectNetwork";
import SelectToken from "./steps/SelectToken";
import SetReceiptAddress from "./steps/SetReceiptAddress";
import If from "../If";
import AccountContext from "../../provider/AccountContext";
import { isTronNetwork } from "../../web3/config/config-utils";
import { getBridgeBalance, getTokenBalance } from "../../web3/web3.service";
import bigDecimal from "js-big-decimal";
import { useTranslation } from "react-i18next";

enum Steps {
  selectNetwork,
  selectToken,
  setAddress,
}

interface Props {
  onHistory: () => void;
  onProgress: (startDate: Date) => void;
  onError: () => void;
}

const Bridge = ({ onHistory, onProgress, onError }: Props) => {
  const { t } = useTranslation();
  const { account, networkId } = useContext(AccountContext);

  const [step, setStep] = useState<Steps>(Steps.selectNetwork);

  const [sourceNetwork, setSourceNetwork] = useState<Option | undefined>();
  const [targetNetwork, setTargetNetwork] = useState<Option | undefined>();
  const [sourceToken, setSourceToken] = useState<Option | undefined>();
  const [selectedTargetToken, setSelectedTargetToken] = useState<Option>();
  const [tokenBalance, setTokenBalance] = useState<bigDecimal>(new bigDecimal(0));
  const [bridgeBalance, setBridgeBalance] = useState<bigDecimal>(new bigDecimal(0));
  const [balanceWithFee, setBalanceWithFee] = useState<bigDecimal>(new bigDecimal(0));
  const [tokenAmount, setTokenAmount] = useState<bigDecimal | undefined>();
  const [receiptAddress, setReceiptAddress] = useState<string | undefined>();
  const [receiveAmount, setReceiveAmount] = useState<bigDecimal | undefined>();

  const getNewBigDecimal = (number: number): bigDecimal => {
    return new bigDecimal(number);
  };

  useEffect(() => {
    if (
      sourceNetwork &&
      !isTronNetwork(sourceNetwork.args.id) &&
      targetNetwork &&
      !isTronNetwork(targetNetwork.args.id)
    ) {
      setReceiptAddress(account);
    }
  }, [sourceNetwork, targetNetwork, account, networkId, setReceiptAddress]);

  useEffect(() => {
    if (account && sourceToken) {
      getTokenBalance(account, sourceToken.args.token)
        .then((balance) => setTokenBalance(balance))
        .catch(() => setTokenBalance(new bigDecimal(0)));
    }
  }, [account, sourceToken]);
  useEffect(() => {
    if (account && selectedTargetToken && targetNetwork) {
      getBridgeBalance(targetNetwork.args.id, selectedTargetToken.args.token)
        .then((balance) => setBridgeBalance(balance))
        .catch(() => setBridgeBalance(new bigDecimal(0)));
    }
  }, [account, selectedTargetToken, targetNetwork]);

  useEffect(() => {
    const getBalanceWithFee = (currentToken: Option, amount: bigDecimal): void => {
      if (currentToken && amount) {
        const {
          args: { token },
        } = currentToken;
        const commissionRate = getNewBigDecimal((Number(amount.getValue()) * token.fee.getValue()) / 100);
        const fixRate = getNewBigDecimal(token.feeBase.getValue());

        setBalanceWithFee(amount.subtract(fixRate).subtract(commissionRate));
      }
    };

    getBalanceWithFee(sourceToken as Option, tokenBalance as bigDecimal);
  }, [tokenBalance, sourceToken]);

  const getAmount = (currentToken: Option, amount: bigDecimal, calc?: boolean): void => {
    if (currentToken && amount) {
      const {
        args: { token },
      } = currentToken;
      const commissionRate = getNewBigDecimal((Number(amount.getValue()) * token.fee.getValue()) / 100);
      const fixRate = getNewBigDecimal(token.feeBase.getValue());
      if (calc) {
        setReceiveAmount(amount);
        setTokenAmount(amount.add(fixRate).add(commissionRate));
      } else {
        setReceiveAmount(amount.subtract(fixRate).subtract(commissionRate));
        setTokenAmount(amount);
      }
    }
  };

  const handleChangeToken = (option?: Option) => {
    setSelectedTargetToken(option);
    getAmount(sourceToken as Option, tokenAmount as bigDecimal);
  };

  const handleChangeTokenAmount = (amount?: bigDecimal) => {
    getAmount(sourceToken as Option, amount as bigDecimal);
  };

  const handleChangeReceiveAmount = (amount?: bigDecimal, calc?: boolean) => {
    getAmount(sourceToken as Option, amount as bigDecimal, calc);
  };

  return (
    <div className="bridgeContainer">
      <div className="header__block">
        <h1>{t("bridge.crossChain")}</h1>
        <button onClick={() => onHistory()} className="button button_history">
          <div className="button__images">
            <img className="button__outer" src="/images/history-outer.svg" alt="history" />
            <img className="button__inner" src="/images/history-inner.svg" alt="history" />
          </div>
        </button>
        <If condition={step === Steps.selectToken}>
          <div className="header__navigation">
            <div className="header__billet">
              {sourceNetwork?.value}
              {"-"}
              {targetNetwork?.value}
            </div>
          </div>
        </If>
        <If condition={step === Steps.setAddress}>
          <div className="header__navigation">
            <div className="header__billet">
              {sourceNetwork?.value}
              {"-"}
              {targetNetwork?.value}
            </div>
            {tokenAmount && (
              <div className="header__billet">
                {Number(tokenAmount.getValue()).toFixed(2)} {selectedTargetToken?.label}
              </div>
            )}
          </div>
        </If>
      </div>
      <div className="bridgeContainer__actions">
        <If condition={step === Steps.selectNetwork}>
          <SelectNetwork
            sourceNetwork={sourceNetwork}
            onSourceNetworkSelect={setSourceNetwork}
            targetNetwork={targetNetwork}
            onTargetNetworkSelect={setTargetNetwork}
            onNext={() => setStep(Steps.selectToken)}
          />
        </If>
        <If condition={step === Steps.selectToken}>
          <SelectToken
            sourceNetwork={sourceNetwork}
            targetNetwork={targetNetwork}
            selectedTargetToken={selectedTargetToken}
            setSourceTokens={setSourceToken}
            onSelectTargetToken={handleChangeToken}
            tokenAmount={tokenAmount}
            tokenBalance={tokenBalance}
            onTokenAmountChange={handleChangeTokenAmount}
            sourceToken={sourceToken}
            onBack={() => setStep(Steps.selectNetwork)}
            onNext={() => setStep(Steps.setAddress)}
          />
        </If>
        <If condition={step === Steps.setAddress}>
          <SetReceiptAddress
            sourceNetwork={sourceNetwork}
            targetNetwork={targetNetwork}
            selectedTargetToken={selectedTargetToken}
            sourceToken={sourceToken}
            tokenBalance={tokenBalance}
            balanceWithFee={balanceWithFee}
            bridgeBalance={bridgeBalance}
            tokenAmount={tokenAmount}
            receiptAddress={receiptAddress}
            onReceiptAddressChange={setReceiptAddress}
            receiveAmount={receiveAmount}
            onReceiveAmountChange={handleChangeReceiveAmount}
            onBack={() => setStep(Steps.selectToken)}
            onComplete={() => onProgress(new Date())}
            onError={onError}
          />
        </If>
      </div>
    </div>
  );
};

export default Bridge;
