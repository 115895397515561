import React, { useState} from "react"
import cn from "classnames"
import { ICoin } from "../../pages/Swap/coins"

import coinImg from 'assets/images/question.svg'
import "./CoinsDropdown.scss"

type Props = {
  currentCoin: ICoin
  onSelect: (coin: ICoin) => void
  toggleDropdown: () => void
  item: any
}


export const CoinsDropdownItem = ({ currentCoin, onSelect, toggleDropdown, item }: Props): JSX.Element => {
  const [imageLoading, setImageLoading] = useState(true)

  return (
          <button
                  onClick={() => {
                    onSelect(item)
                    toggleDropdown()
                  }}
                  className={cn("coin-dropdown-content-item", {
                    active: item.address === currentCoin?.address,
                  })}
          >
            <div className='coin-dropdown-content-item-img-wrapper'>
              {imageLoading && <img src={coinImg} alt='preview' className='coin-dropdown-content-item-preview'/>}
              <img src={`/images_eth_and_bsc/${item.address}.png`} onLoad={() => setImageLoading(false)} alt="network" className="coin-dropdown-content-item-img" />
            </div>
            <div className="coin-dropdown-content-item-name">{item?.name}</div>
          </button>
  )
}
