import { IToken } from "./web3.interfaces";
import { getBridge, getDestination, getNetwork, isTronNetwork } from "./config/config-utils";
import { connectCached } from "./web3.connector";
import bigDecimal from "js-big-decimal";
import config from "./config/config";
import { TronProvider } from "./providers/tron.provider";
import { IProvider } from "./providers/web3.provider";
import { EvmProvider } from "./providers/evm.provider";

export const listDestinations = async (networkId: number): Promise<number[]> => {
  const provider = await connectCached();
  const bridgeContract = getBridge(networkId);
  return (await provider.listDestinations(bridgeContract)).map((d) => Number(d));
};

export const getTokenBalance = async (account: string, token: IToken): Promise<bigDecimal> => {
  const provider = await connectCached();
  return provider.getTokenBalance(account, token.contract, token.decimals);
};

export const getBridgeBalance = async (networkId: number, token: IToken): Promise<bigDecimal> => {
  const network: any = getNetwork(networkId);
  let provider: IProvider;
  if (isTronNetwork(networkId)) {
    provider = TronProvider.create(network.rpcUrl);
  } else {
    provider = EvmProvider.create(network.rpcUrl);
  }
  const receiveToken = (await provider.listTokens(network.bridge))[token.tokenId];
  if (receiveToken) {
    return provider.getTokenBalance(network.bridge, receiveToken.token, parseInt(receiveToken.decimals));
  } else {
    return Promise.reject("target token not found");
  }
};

export const loadTokens = async (network: any): Promise<IToken[]> => {
  const destinationNetwork = getNetwork(network?.args?.id);
  const provider =
    network.value === "TRON"
      ? await TronProvider.create(destinationNetwork?.rpcUrl)
      : await EvmProvider.create(destinationNetwork?.rpcUrl);
  const bridgeContract = getBridge(network.args.id);
  const tokens = await provider.listTokens(bridgeContract);
  return await Promise.all(
    tokens.map(async (token, idx) => {
      const decimals = parseInt(token.decimals);
      const [name, symbol] = await Promise.all([
        provider.getTokenName(token.token),
        provider.getTokenSymbol(token.token),
      ]);
      return {
        tokenId: idx,
        name,
        symbol,
        contract: token.token,
        bonus: provider.toAmount(token.bonus, decimals),
        dailyLimit: provider.toAmount(token.dailyLimit, decimals),
        decimals: decimals,
        fee: new bigDecimal(token.fee / 1000),
        feeBase: provider.toAmount(token.feeBase, decimals),
        feeTarget: token.feeTarget,
        minAmount: provider.toAmount(token.minAmount, decimals),
        maxAmount: provider.toAmount(token.maxAmount, decimals),
      };
    })
  );
};

export const allowance = async (networkId: number, token: IToken, account: string) => {
  const provider = await connectCached();
  const bridgeContract = getBridge(networkId);
  return provider.allowance(token.contract, account, bridgeContract);
};

export const approve = async (networkId: number, token: IToken, account: string, amount: bigDecimal) => {
  const provider = await connectCached();
  const bridgeContract = getBridge(networkId);
  if (amount.getValue() !== "0") {
    const decimals = new bigDecimal((10 ** token.decimals).toString());
    const newAmount = amount.multiply(decimals);
    return provider.approve(token.contract, account, bridgeContract, newAmount, networkId);
  } else {
    return provider.approve(token.contract, account, bridgeContract, amount, networkId);
  }
};

export const createOrder = async (
  networkId: number,
  account: string,
  token: IToken,
  receiptAddress: string,
  targetNetworkId: number,
  amount: bigDecimal,
  onPending: (txid: string) => void,
  onError: (error: any) => void
): Promise<void> => {
  const provider = await connectCached();
  const bridgeContract = getBridge(networkId);
  const destinationId = getDestination(targetNetworkId);
  if (!(await listDestinations(networkId)).includes(destinationId)) {
    alert("Destination not supported");
    return Promise.reject("destination not supported");
  }
  return provider
    .createOrder(
      networkId,
      account,
      bridgeContract,
      token.tokenId,
      amount,
      token.decimals,
      destinationId,
      receiptAddress,
      onPending,
      onError
    )
    .catch((error) => onError(error));
};

export const listNetworks = async (networkId: number): Promise<number[]> => {
  let idx = 0;
  const networkIds = [];
  const destinations = await Promise.all(
    Object.values(config).map((network) => {
      if (network.networkId === networkId) {
        return Promise.resolve([]);
      }
      let provider: IProvider;
      if (isTronNetwork(network.networkId)) {
        provider = TronProvider.create(network.rpcUrl);
      } else {
        provider = EvmProvider.create(network.rpcUrl);
      }
      return provider.listDestinations(network.bridge);
    })
  );
  for (const network of Object.values(config)) {
    if (destinations[idx].length > 0) {
      networkIds.push(network.networkId);
    }
    idx++;
  }
  return networkIds;
};
