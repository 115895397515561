import config from "../web3/config/config";

interface NetworkOption {
  value: string;
  label: string;
  args: {
    img: string;
    id: number;
  };
}

export const Networks: NetworkOption[] = [
  {
    value: "POLYGON",
    label: "Polygon",
    args: { img: "/images/networks/polygon.svg", id: config.polygon.networkId },
  },
  {
    value: "BSC",
    label: "Binance Smart Chain",
    args: { img: "/images/networks/binance.svg", id: config.binance.networkId },
  },
  {
    value: "ETHEREUM",
    label: "Ethereum",
    args: { img: "/images/networks/ethereum.svg", id: config.ethereum.networkId },
  },
  //   {
  //     value: "FANTOM",
  //     label: "Fantom",
  //     args: { img: "/images/networks/fantom.svg", id: config.fantom.networkId },
  //   },
  {
    value: "AVALANCHE",
    label: "Avalanche",
    args: { img: "/images/networks/avalanche.svg", id: config.avalanche.networkId },
  },
  {
    value: "CRONOS",
    label: "Cronos",
    args: { img: "/images/networks/cronos.svg", id: config.cronos.networkId },
  },
  {
    value: "TRON",
    label: "Tron",
    args: { img: "/images/networks/tron.svg", id: config.tron.networkId },
  },
];
