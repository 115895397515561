import { InjectedConnector } from "@web3-react/injected-connector"
import { WalletConnectConnector } from "@web3-react/walletconnect-connector"

const injected = new InjectedConnector({
  supportedChainIds: [1, 97, 56],
})

const walletconnect = new WalletConnectConnector({
  rpc: { 56: "https://data-seed-prebsc-2-s1.binance.org:8545/" },
  chainId: 56,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
})

export const connectors = {
  injected: injected,
  walletConnect: walletconnect,
}