import { IProvider } from "./web3.provider";
import Web3 from "web3";
import { Contract } from "web3-eth-contract";
import { bridgeAbi } from "../abi/bridge";
import { tokenAbi } from "../abi/token";
import bigDecimal from "js-big-decimal";
import { convertFromWei, convertToWei } from "../web3.utils";

export class EvmProvider implements IProvider {
  readonly web3: Web3;

  constructor(provider: any) {
    this.web3 = new Web3(provider);
  }

  public static create(url: string) {
    const fullNode = new Web3.providers.HttpProvider(url);
    return new EvmProvider(fullNode);
  }

  public async getAccount(): Promise<string> {
    const accounts = await this.web3.eth.getAccounts();
    return accounts[0];
  }

  public async getNetworkId(): Promise<number> {
    return this.web3.eth.net.getId();
  }

  public toAmount(amount: string, decimals: number): bigDecimal {
    return convertFromWei(amount, decimals);
  }

  public async getTokenBalance(account: string, contract: string, decimals: number): Promise<bigDecimal> {
    const tokenContract: Contract = new this.web3.eth.Contract(tokenAbi, contract);
    const balance = await tokenContract.methods.balanceOf(account).call();
    return this.toAmount(balance, decimals);
  }

  public getTokenName(contract: string): Promise<string> {
    const tokenContract: Contract = new this.web3.eth.Contract(tokenAbi, contract);
    return tokenContract.methods.name().call();
  }

  public getTokenSymbol(contract: string): Promise<string> {
    const tokenContract: Contract = new this.web3.eth.Contract(tokenAbi, contract);
    return tokenContract.methods.symbol().call();
  }

  public listDestinations(contract: string): Promise<number[]> {
    const bridgeContract: Contract = new this.web3.eth.Contract(bridgeAbi, contract);
    return bridgeContract.methods.getDestinations().call();
  }

  public async listTokens(contract: string): Promise<any[]> {
    const bridgeContract: Contract = new this.web3.eth.Contract(bridgeAbi, contract);
    return bridgeContract.methods.getTokens().call();
  }

  public allowance(contract: string, address: string, bridgeContract: string): Promise<number> {
    const tokenContract: Contract = new this.web3.eth.Contract(tokenAbi, contract);
    return tokenContract.methods.allowance(address, bridgeContract).call();
  }

  public async approve(
    contract: string,
    address: string,
    bridgeContract: string,
    amount: bigDecimal,
    networkId: number
  ): Promise<any> {
    const tokenContract: Contract = new this.web3.eth.Contract(tokenAbi, contract);
    let gasEstimate = await tokenContract.methods
      .approve(bridgeContract, amount.getValue())
      .estimateGas({ from: address });
    if (networkId === 250) {
      gasEstimate = Math.round(gasEstimate * 1.2);
    }
    if (networkId === 137) {
      gasEstimate = Math.round(gasEstimate * 2);
      const data = await fetch("https://gasstation.polygon.technology/v2");
      const dataJson = await data.json();
      const gas = dataJson["fast"];
      const priority = Math.trunc(gas.maxPriorityFee * 10 ** 9);
      const max = Math.trunc(gas.maxFee * 10 ** 9);
      const maxFeePerGas = max.toString();
      const maxPriorityFeePerGas = priority.toString();

      return tokenContract.methods
        .approve(bridgeContract, amount.getValue())
        .send({ from: address, gas: this.web3.utils.toHex(gasEstimate), maxFeePerGas, maxPriorityFeePerGas });
    }
    return tokenContract.methods
      .approve(bridgeContract, amount.getValue())
      .send({ from: address, gas: this.web3.utils.toHex(gasEstimate) });
  }

  public async createOrder(
    networkId: number,
    address: string,
    contract: string,
    tokenId: number,
    amount: bigDecimal,
    decimals: number,
    destination: number,
    receiptAddress: string,
    onSent: (txid: string) => void,
    onError: (error: any) => void
  ): Promise<void> {
    const bridgeContract: Contract = new this.web3.eth.Contract(bridgeAbi, contract);
    const wei = convertToWei(amount, decimals);

    let gasEstimate = await bridgeContract.methods
      .create(tokenId.toString(), String(wei), destination.toString(), receiptAddress)
      .estimateGas({ from: address });
    if (networkId === 137) {
      gasEstimate = Math.round(gasEstimate * 1.2);
      const data = await fetch("https://gasstation.polygon.technology/v2");
      const dataJson = await data.json();
      const gas = dataJson["fast"];
      const priority = Math.trunc(gas.maxPriorityFee * 10 ** 9);
      const max = Math.trunc(gas.maxFee * 10 ** 9);
      const maxFeePerGas = max.toString();
      const maxPriorityFeePerGas = priority.toString();

      return bridgeContract.methods
        .create(tokenId.toString(), String(wei), destination.toString(), receiptAddress)
        .send({ from: address, gas: this.web3.utils.toHex(gasEstimate), maxFeePerGas, maxPriorityFeePerGas })
        .on("transactionHash", (txid: string) => onSent(txid))
        .on("error", (error: any) => onError(error));
    }
    return bridgeContract.methods
      .create(tokenId.toString(), String(wei), destination.toString(), receiptAddress)
      .send({ from: address, gas: this.web3.utils.toHex(gasEstimate) })
      .on("transactionHash", (txid: string) => onSent(txid))
      .on("error", (error: any) => onError(error));
  }

  public onAccountChange(): void {
    // @ts-ignore
    this.web3.currentProvider.on("accountsChanged", () => window.location.reload());
  }

  public onNetworkChange(): void {
    // @ts-ignore
    this.web3.currentProvider.on("chainChanged", () => window.location.reload());
  }
}
