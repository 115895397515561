import React from 'react';
import './PaginationPanel.scss';
import { useTranslation } from 'react-i18next';

interface IPaginationPanel {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  maxButtons: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  onPage: (pageNumber: number) => void;
}

const PaginationPanel = (props: IPaginationPanel) => {
  const { t } = useTranslation();
  const { page, pageCount, maxButtons, hasPreviousPage, hasNextPage, onPage } = props;

  const moreThatMaxButtons = pageCount > maxButtons + 1;
  const buttonsCount = moreThatMaxButtons ? maxButtons : pageCount;

  return (
      <div className="paginationPanel__container">
        <button
            className={`${hasPreviousPage ? 'paginationPanel__button' : 'paginationPanel__buttonDisabled'} button`}
            onClick={() => hasPreviousPage && onPage(page - 1)}
        >
          {t('pagination.prevBtn')}
        </button>
        {Array(buttonsCount).fill(0).map((ignore, idx) => {
          const pageNumber = page > 1 ? page + (idx - 1) : page + idx;
          if (pageNumber > pageCount) {
            return null;
          }
          return (
              <button
                  key={idx}
                  className={`button ${pageNumber === page ? 'paginationPanel__activePage' : ''} paginationPanel__button`}
                  onClick={() => page !== pageNumber && onPage(pageNumber)}
              >
                {pageNumber}
              </button>
          );
        })}
        {moreThatMaxButtons && page < pageCount - 2 && (
            <button className="paginationPanel__buttonNoActive button">
              ...
            </button>
        )}
        {moreThatMaxButtons && page < pageCount - 2 && (
            <button className="paginationPanel__button button">
              {pageCount}
            </button>
        )}
        <button
            className={`${hasNextPage ? 'paginationPanel__button' : 'paginationPanel__buttonDisabled'} button`}
            onClick={() => hasNextPage && onPage(page + 1)}
        >
          {t('pagination.nextBtn')}
        </button>
      </div>
  );
};

export default PaginationPanel;