import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Option } from '../../Dropdown';
import { Networks } from '../../../constants/networks';
import AccountContext from '../../../provider/AccountContext';
import { listDestinations, listNetworks } from '../../../web3/web3.service';
import { getDestination, getNetwork, isTronNetwork } from '../../../web3/config/config-utils';
import { useTranslation } from 'react-i18next';
import { Connectors } from '../../../web3/web3.connector';
import { handleMetaMaskErrors, handleTronLinkError } from '../../../helpers/errors';

interface Props {
  sourceNetwork?: Option;
  onSourceNetworkSelect: (network?: Option) => void;
  targetNetwork?: Option;
  onTargetNetworkSelect: (network?: Option) => void;
  onNext: () => void;
}

const SelectNetwork = (props: Props) => {
  const { t } = useTranslation();
  const { account, networkId, connect, connectTo } = useContext(AccountContext);
  const { sourceNetwork, onSourceNetworkSelect, targetNetwork, onTargetNetworkSelect, onNext } = props;

  const [sourceNetworks, setSourceNetworks] = useState<any[]>([]);
  const [targetNetworks, setTargetNetworks] = useState<any[]>([]);

  const [sourceLoading, setSourceLoading] = useState(false);
  const [targetLoading, setTargetLoading] = useState(false);

  useEffect(() => {
    onSourceNetworkSelect(Networks.find(n => n.args.id === networkId));
  }, [networkId, onSourceNetworkSelect]);

  useEffect(() => {
    if (networkId) {
      setSourceLoading(true);
      listNetworks(networkId)
          .then(listNetworks => {
            const networks = [];
            for (const network of Networks) {
              if (listNetworks.includes(network.args.id)) {
                networks.push(network);
              }
            }
            setSourceNetworks(networks);
            setSourceLoading(false);
          })
          .catch(() => setSourceLoading(false));
    }
  }, [networkId]);

  useEffect(() => {
    if (networkId) {
      setTargetLoading(true);
      listDestinations(networkId)
          .then(destinations => {
            const allNetworks = [];
            for (const network of Networks) {
              const destination = getDestination(network.args.id);
              if (destinations.includes(destination) && sourceNetwork) {
                allNetworks.push(network);
              }
            }

            const networks = allNetworks.filter((network: Option) => network.args.id !== sourceNetwork?.args.id)
            setTargetNetworks(networks);
            setTargetLoading(false);
          })
          .catch(() => setTargetLoading(false));
    }
  }, [networkId, sourceNetwork]);

  const handleSwitchNetwork = async (option: any) => {
    if (networkId) {
      const isNextTron = isTronNetwork(option.args.id);
      if (isNextTron) {
        connect(Connectors.tronlink).catch(handleTronLinkError);
      } else {
        const nextNetwork = getNetwork(option.args.id);
        connectTo(Connectors.metamask, nextNetwork).catch(handleMetaMaskErrors);
      }
    }
  };

  return (
      <React.Fragment>
        <Dropdown
            selectedOption={sourceNetwork}
            label={t('bridge.labelFrom')}
            placeholder={t('bridge.sourceNetwork')}
            options={sourceNetworks}
            onSelect={(option: Option) => handleSwitchNetwork(option)}
            disabled={!account}
            loading={sourceLoading}
        />
        <Dropdown
            selectedOption={targetNetwork}
            label={t('bridge.labelTo')}
            options={targetNetworks}
            placeholder={t('bridge.targetNetwork')}
            onSelect={(option: Option) => onTargetNetworkSelect(option)}
            disabled={!account}
            loading={targetLoading}
        />
        <button
            onClick={() => onNext()}
            className="bridgeContainer__nextBtn"
            disabled={!targetNetwork || !sourceNetwork || !account}
        >
          <span>{t('bridge.nextBtn')}</span>
        </button>
      </React.Fragment>
  );
};

export default SelectNetwork;